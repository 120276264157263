import { env } from '../env';


export default {
    get host(): string {
        return env.REACT_APP_API_HOST || 'http://localhost'
    },
    get port(): string {
        return env.REACT_APP_API_PORT || ''
    },
    get path(): string {
        return env.REACT_APP_API_PATH || '/api/v1'
    },
}
