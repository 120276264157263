import styles from '../index.module.scss'
import { useForm } from 'react-hook-form'
import { Button } from '../../button'
import { useMutation, useQueryClient } from 'react-query'
import { isFileValidByExt } from '../../../utils'
import { createRuleVariables, FixType } from '../../../types/data'
import { RuleCategory } from '../../../config/enums'
import * as ruleApi from '../../../app/services/api/ruleApi'
import { toast } from 'react-toastify'

interface TypeProps {
    category: RuleCategory
    closeModal(): void
}

interface FormData {
    category: RuleCategory
    file: File | undefined
}

export const RuleCreateForm = ({ category, closeModal }: TypeProps) => {
    const initialFormData: FormData = {
        category,
        file: undefined,
    }

    const queryClient = useQueryClient()
    const { mutate: createRule, isLoading } = useMutation(
        ({ category, data }: createRuleVariables) => ruleApi.createRule(category, { data }),
        {
            onSuccess: () => {
                toast.success('Rule was successfully created!')
                queryClient.invalidateQueries('rules')
                closeModal()
            },
            onError: (error: Error) => {
                toast.error(`Something went wrong: ${error.message}`)
            },
        },
    )

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({
        mode: 'onChange',
        defaultValues: initialFormData,
    })

    const onSubmit = (data: FixType) => {
        const file = data.file[0]
        const category = data.category

        const reader = new FileReader()
        reader.readAsDataURL(file)

        reader.onload = () => {
            const base64String = (reader.result as string).replace('data:', '').replace(/^.+,/, '')
            createRule({ category, data: base64String })
        }
        reader.onerror = (error) => alert(error)
    }

    return (
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <label className={`${styles.inputWrapper} ${styles.fullWidth}`}>
                <span className={styles.inputLabel}>Category</span>
                <select
                    {...register('category', { required: 'Category is required!' })}
                    className={styles.inputControl}
                    aria-invalid={errors.category ? 'true' : 'false'}
                >
                    {Object.values(RuleCategory).map((item) => (
                        <option key={item} value={item}>
                            {item}
                        </option>
                    ))}
                </select>
            </label>

            <label className={`${styles.inputWrapper} ${styles.fullWidth}`}>
                <span className={styles.inputLabel}>Excel file</span>
                <input
                    type={'file'}
                    required
                    accept={
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                    }
                    {...register('file', {
                        required: 'Excel file is required!',
                        validate: {
                            extension: (files: FixType) =>
                                isFileValidByExt(files[0], ['xlsx', 'xls']) ||
                                'File must be with xlsx or xls extension!',
                        },
                    })}
                    className={styles.inputControl}
                    aria-invalid={errors.file ? 'true' : 'false'}
                />

                {errors.file && (
                    <p role='alert' className={styles.inputError}>
                        {errors.file?.message}
                    </p>
                )}
            </label>

            <div className={styles.action}>
                <Button type={'submit'} disabled={isLoading}>
                    Save
                </Button>
            </div>
        </form>
    )
}
