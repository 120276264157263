import styles from '../index.module.scss'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useForm } from 'react-hook-form'
import { FixType, IDictionaryKeyword } from '../../../types/data'
import * as dictionaryKeywordApi from '../../../app/services/api/dictionaryKeywordApi'
import { Button } from '../../button'
import { toast } from 'react-toastify'

export const DictionaryKeywordDetail = ({ id, closeModal }: { id: number; closeModal(): void }) => {
    const queryClient = useQueryClient()
    const { data } = useQuery(['dictionary-keyword', id], () => dictionaryKeywordApi.get(id))
    const { mutate: updateDictionaryKeyword, isLoading } = useMutation(
        (dictionaryCountry: IDictionaryKeyword) =>
            dictionaryKeywordApi.updateDictionaryKeyword(dictionaryCountry),
        {
            onSuccess: () => {
                toast.success('Dictionary Keyword was successfully updated!')
                queryClient.invalidateQueries(['dictionary-keyword', id])
                queryClient.invalidateQueries(['dictionary-keywords'])
                closeModal()
            },
            onError: (error: Error) => {
                toast.error(`Something went wrong: ${error.message}`)
            },
        },
    )

    if (data) {
        return (
            <DictionaryKeywordForm
                dictionaryKeyword={data}
                isLoading={isLoading}
                onSubmit={updateDictionaryKeyword}
            />
        )
    }

    return <div>Loading...</div>
}

interface TypeProps {
    dictionaryKeyword: IDictionaryKeyword
    isLoading: boolean
    onSubmit: (v: FixType, p: { onSuccess: () => void }) => void
}

const DictionaryKeywordForm = ({ dictionaryKeyword, isLoading, onSubmit }: TypeProps) => {
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm<IDictionaryKeyword>({
        defaultValues: dictionaryKeyword,
        mode: 'onBlur',
    })

    return (
        <form
            className={styles.form}
            onSubmit={handleSubmit((values: IDictionaryKeyword) =>
                onSubmit(values, { onSuccess: () => reset() }),
            )}
        >
            <label className={`${styles.inputWrapper} ${styles.fullWidth}`}>
                <span className={styles.inputLabel}>ID</span>
                <input
                    type={'number'}
                    disabled={true}
                    className={styles.inputControl}
                    aria-invalid={errors.id ? 'true' : 'false'}
                    {...register('id', { required: 'ID is required!' })}
                />
            </label>

            <label className={`${styles.inputWrapper} ${styles.fullWidth}`}>
                <span className={styles.inputLabel}>Data</span>
                <input
                    type={'text'}
                    {...register('data', {
                        required: 'Data is required!',
                    })}
                    className={styles.inputControl}
                    aria-invalid={errors.data ? 'true' : 'false'}
                />
                {errors.data && (
                    <p role='alert' className={styles.inputError}>
                        {errors.data?.message}
                    </p>
                )}
            </label>

            <div className={styles.action}>
                <Button type={'submit'} disabled={isLoading}>
                    Save
                </Button>
            </div>
        </form>
    )
}
