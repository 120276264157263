import styles from '../index.module.scss'
import { useForm } from 'react-hook-form'
import { IDictionaryKeyword } from '../../../types/data'
import { Button } from '../../button'
import { useMutation, useQueryClient } from 'react-query'
import * as dictionaryKeywordApi from '../../../app/services/api/dictionaryKeywordApi'
import { toast } from 'react-toastify'

interface TypeProps {
    closeModal(): void
}

export const DictionaryKeywordCreateForm = ({ closeModal }: TypeProps) => {
    const dictionaryKeyword: IDictionaryKeyword = {
        data: '',
    }

    const queryClient = useQueryClient()
    const { mutate: createDictionaryKeyword, isLoading } = useMutation(
        (dictionaryKeyword: IDictionaryKeyword) =>
            dictionaryKeywordApi.createDictionaryKeyword(dictionaryKeyword),
        {
            onSuccess: () => {
                toast.success('Dictionary keyword was successfully created!')
                queryClient.invalidateQueries('dictionary-keywords')
                closeModal()
            },
            onError: (error: Error) => {
                toast.error(`Something went wrong: ${error.message}`)
            },
        },
    )
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({
        mode: 'onBlur',
        defaultValues: dictionaryKeyword,
    })

    const onSubmit = (data: IDictionaryKeyword) => createDictionaryKeyword(data)

    return (
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <label className={`${styles.inputWrapper} ${styles.fullWidth}`}>
                <span className={styles.inputLabel}>Data</span>
                <input
                    type={'text'}
                    {...register('data', {
                        required: 'Data is required!',
                    })}
                    className={styles.inputControl}
                    aria-invalid={errors.data ? 'true' : 'false'}
                />
                {errors.data && (
                    <p role='alert' className={styles.inputError}>
                        {errors.data?.message}
                    </p>
                )}
            </label>

            <div className={styles.action}>
                <Button type={'submit'} disabled={isLoading}>
                    Save
                </Button>
            </div>
        </form>
    )
}
