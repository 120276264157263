import { API } from 'aws-amplify'
import { FixType, IFullTransaction, ISearchTransaction } from '../../../types/data'
import { API_RULE_NAME } from '../../../config/aws'

export const get = async (uuid: string, inc: number): Promise<IFullTransaction> => {
    return await API.get(API_RULE_NAME, `/transactions/${uuid}/${inc}`, {})
}

export const getAll = async (start: string, end: string): Promise<ISearchTransaction[]> => {
    return await API.get(API_RULE_NAME, '/transactions', {
        queryStringParameters: {
            start,
            end,
        },
    })
}

export const createRelease = async (
    uuid: string,
    inc: number,
    reason: string,
    body: FormData,
): Promise<FixType> => {
    return await API.put(API_RULE_NAME, `/transactions/${uuid}/${inc}/release`, {
        queryStringParameters: {
            reason,
        },
        body,
    })
}
