import styles from './index.module.scss'
import logo from './logo.png'
import { Link } from 'react-router-dom'

export const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className='container'>
                <div className={styles.footerContent}>
                    <Link to={'/'} className={styles.footerLogoWrapper}>
                        <img src={logo} alt='h3llo logo' className={styles.footerLogo} />
                    </Link>
                    <span className={styles.copyright}>
                        Copyright {new Date().getFullYear()} © h3llo.com
                    </span>
                </div>
            </div>
        </footer>
    )
}
