import styles from '../index.module.scss'
import { useForm } from 'react-hook-form'
import { FixType, IDictionaryCountry } from '../../../types/data'
import { Risk } from '../../../config/enums'
import countries from 'i18n-iso-countries'
import { Button } from '../../button'
import { useMutation, useQueryClient } from 'react-query'
import * as dictionaryCountryApi from '../../../app/services/api/dictionaryCountryApi'
import { toast } from 'react-toastify'

interface TypeProps {
    closeModal(): void
}

export const DictionaryCountryCreateForm = ({ closeModal }: TypeProps) => {
    const dictionaryCountry: IDictionaryCountry = {
        risk: Risk.Sanction,
        countryCode: '',
    }

    const countriesObj = countries.getNames('en', { select: 'official' })
    const countriesArr = Object.entries(countriesObj).map(([key, value]) => ({
        label: value,
        value: key,
    }))

    const queryClient = useQueryClient()
    const { mutate: createDictionaryCountry, isLoading } = useMutation(
        (dictionaryCountry: IDictionaryCountry) =>
            dictionaryCountryApi.createDictionaryCountry(dictionaryCountry),
        {
            onSuccess: () => {
                toast.success('Dictionary country was successfully created!')
                queryClient.invalidateQueries('dictionary-countries')
                closeModal()
            },
            onError: (error: Error) => {
                toast.error(`Something went wrong: ${error.message}`)
            },
        },
    )
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({
        mode: 'onBlur',
        defaultValues: dictionaryCountry,
    })

    const onSubmit = (data: FixType) => createDictionaryCountry(data)

    return (
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <label className={`${styles.inputWrapper} ${styles.fullWidth}`}>
                <span className={styles.inputLabel}>Risk</span>
                <select
                    className={styles.inputControl}
                    aria-invalid={errors.risk ? 'true' : 'false'}
                    {...register('risk', { required: 'Risk is required!' })}
                >
                    {Object.values(Risk).map((item) => (
                        <option key={item} value={item}>
                            {item}
                        </option>
                    ))}
                </select>
                {errors.risk && (
                    <p role='alert' className={styles.inputError}>
                        {errors.risk?.message}
                    </p>
                )}
            </label>

            <label className={`${styles.inputWrapper} ${styles.fullWidth}`}>
                <span className={styles.inputLabel}>Country</span>
                <select
                    className={styles.inputControl}
                    aria-invalid={errors.countryCode ? 'true' : 'false'}
                    {...register('countryCode', { required: 'Country is required!' })}
                >
                    {countriesArr.map(({ label, value }) => (
                        <option key={value} value={value}>
                            {label}
                        </option>
                    ))}
                </select>
                {errors.countryCode && (
                    <p role='alert' className={styles.inputError}>
                        {errors.countryCode?.message}
                    </p>
                )}
            </label>

            <div className={styles.action}>
                <Button type={'submit'} disabled={isLoading}>
                    Save
                </Button>
            </div>
        </form>
    )
}
