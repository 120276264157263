import styles from '../index.module.scss'
import { useForm } from 'react-hook-form'
import { FixType, IMerchant } from '../../../types/data'
import { ContactRole, MerchantStatus, MerchantType } from '../../../config/enums'

export const MerchantCreateForm = () => {
    const merchant: IMerchant = {
        uuid: '',
        address: {
            line1: '',
            line2: '',
            city: '',
            zip: '',
            country: '',
        },
        bankAccounts: [
            {
                uuid: '',
                iban: '',
                bic: '',
                ownerName: '',
                ownerEmail: '',
                locale: '',
            },
        ],
        businessIdentifier: '',
        businessName: '',
        contacts: [
            {
                name: '',
                role: ContactRole.Agent,
                email: '',
                phoneNumber: '',
            },
        ],
        creationDate: new Date(),
        email: '',
        phoneNumber: '',
        publicBusinessName: '',
        status: MerchantStatus.New,
        totalAmount1mKYC: 0,
        type: MerchantType.LegalEntity,
        url: '',
    }
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({
        mode: 'onBlur',
        defaultValues: merchant,
    })

    const onSubmit = (data: FixType) => console.log(data)

    return (
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <label htmlFor={'uuid'} className={styles.inputWrapper}>
                <span className={styles.inputLabel}>ID</span>
                <input
                    id={'uuid'}
                    type='text'
                    {...register('uuid')}
                    className={styles.inputControl}
                />
            </label>

            <label htmlFor={'businessName'} className={styles.inputWrapper}>
                <span className={styles.inputLabel}>Business Name</span>
                <input
                    id={'businessName'}
                    type='text'
                    {...register('businessName', { required: true })}
                    className={styles.inputControl}
                    aria-invalid={errors.businessName ? 'true' : 'false'}
                />
            </label>

            <label htmlFor={'publicBusinessName'} className={styles.inputWrapper}>
                <span className={styles.inputLabel}>Public Business Name</span>
                <input
                    id={'publicBusinessName'}
                    type='text'
                    {...register('publicBusinessName', { required: true })}
                    className={styles.inputControl}
                />
            </label>

            <label htmlFor={'url'} className={styles.inputWrapper}>
                <span className={styles.inputLabel}>Url</span>
                <input
                    id={'url'}
                    type='text'
                    {...register('url', {
                        pattern:
                            /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/,
                    })}
                    className={styles.inputControl}
                />
            </label>

            <label htmlFor={'phoneNumber'} className={styles.inputWrapper}>
                <span className={styles.inputLabel}>Phone Number</span>
                <input
                    id={'phoneNumber'}
                    type='text'
                    {...register('phoneNumber')}
                    className={styles.inputControl}
                />
            </label>

            <label htmlFor={'email'} className={styles.inputWrapper}>
                <span className={styles.inputLabel}>Email</span>
                <input
                    id={'email'}
                    type='email'
                    {...register('email', {
                        pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                    })}
                    className={styles.inputControl}
                />
            </label>

            <label htmlFor={'businessIdentifier'} className={styles.inputWrapper}>
                <span className={styles.inputLabel}>Business Identifier</span>
                <input
                    id={'businessIdentifier'}
                    type='text'
                    {...register('businessIdentifier', { required: true })}
                    className={styles.inputControl}
                />
            </label>

            <label htmlFor={'country'} className={styles.inputWrapper}>
                <span className={styles.inputLabel}>Country</span>
                <input
                    id={'country'}
                    type='text'
                    {...register('address.country', { required: true })}
                    className={styles.inputControl}
                />
            </label>

            <label htmlFor={'city'} className={styles.inputWrapper}>
                <span className={styles.inputLabel}>City</span>
                <input
                    id={'city'}
                    type='text'
                    {...register('address.city', { required: true })}
                    className={styles.inputControl}
                />
            </label>

            <label htmlFor={'zip'} className={styles.inputWrapper}>
                <span className={styles.inputLabel}>Zip</span>
                <input
                    id={'zip'}
                    type='text'
                    {...register('address.zip', { required: true })}
                    className={styles.inputControl}
                />
            </label>

            <label htmlFor={'line1'} className={styles.inputWrapper}>
                <span className={styles.inputLabel}>Line 1</span>
                <input
                    id={'line1'}
                    type='text'
                    {...register('address.line1', { required: true })}
                    className={styles.inputControl}
                />
            </label>

            <label htmlFor={'line2'} className={styles.inputWrapper}>
                <span className={styles.inputLabel}>Line 2</span>
                <input
                    id={'line2'}
                    type='text'
                    {...register('address.line2')}
                    className={styles.inputControl}
                />
            </label>
        </form>
    )
}
