import styles from './index.module.scss'
import { ReactNode } from 'react'

interface TypeProps {
    type: 'button' | 'submit' | 'reset'
    disabled?: boolean
    action?: 'create' | 'edit' | 'delete'
    onClickHandler?(): void
    children?: ReactNode
    className?: string
}

export const Button = ({
    type,
    action,
    disabled,
    onClickHandler,
    children,
    className,
}: TypeProps) => {
    const classNames = []

    classNames.push(className ? className : styles.button)

    if (action) {
        switch (action) {
            case 'create':
                classNames.push(styles.buttonCreate)
                break
            case 'edit':
                classNames.push(styles.buttonEdit)
                break
            case 'delete':
                classNames.push(styles.buttonDelete)
                break
        }
    }

    return (
        <button
            type={type}
            className={classNames.join(' ')}
            disabled={disabled}
            onClick={onClickHandler}
        >
            {children ?? null}
        </button>
    )
}
