import { Page } from '../../components/page'
import styles from '../../modules/table-pages/index.module.scss'
import { Search } from '../../components/search'
import { Table } from '../../components/table'
import { Button } from '../../components/button'
import { Modal } from '../../components/modal'
import { useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import * as dictionaryMerchantApi from '../../app/services/api/dictionaryMerchantApi'
import { createColumnHelper } from '@tanstack/react-table'
import { toast } from 'react-toastify'
import { IDictionaryMerchant } from '../../types/data'
import { DictionaryMerchantCreateForm } from '../../components/forms/dictionaryMerchant/create-form'
import { DictionaryMerchantDetail } from '../../components/forms/dictionaryMerchant/edit-form'

export const DictionaryMerchantsPage = () => {
    const queryClient = useQueryClient()
    const { isLoading, data: dictionarymerchants } = useQuery(
        'dictionary-merchants',
        dictionaryMerchantApi.getAll,
        {
            onError: (error: Error) => {
                toast.error(`Something went wrong: ${error.message}`)
            },
        },
    )
    const { mutate: deleteDictionaryMerchant } = useMutation(
        (uuid: string) => dictionaryMerchantApi.deleteDictionaryMerchant(uuid),
        {
            onSuccess: () => {
                toast.success('Dictionary Merchant was successfully deleted!')
                queryClient.invalidateQueries(['dictionary-merchants'])
            },
            onError: (error: Error) => {
                toast.error(`Something went wrong: ${error.message}`)
            },
        },
    )

    const [tableData, setTableData] = useState<IDictionaryMerchant[] | undefined>([])
    const [searchFilter, setSearchFilter] = useState<string>('')
    const [activeId, setActiveId] = useState<string>()
    const [openModal, setOpenModal] = useState<boolean>(false)

    const columnHelper = createColumnHelper<IDictionaryMerchant>()
    const columns = useMemo(
        () => [
            columnHelper.accessor('uuid', {
                header: 'UUID',
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor('risk', {
                header: 'Risk',
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor('note', {
                header: 'Note',
                cell: (info) => info.getValue(),
            }),
            columnHelper.display({
                id: 'actions',
                header: '',
                cell: (info) => (
                    <div className={`${styles.dFlex} ${styles.g1}`}>
                        <Button
                            type={'button'}
                            action={'edit'}
                            onClickHandler={() =>
                                info.row.original.uuid ? onClickEdit(info.row.original.uuid) : null
                            }
                        ></Button>
                        <Button
                            type={'button'}
                            action={'delete'}
                            onClickHandler={() =>
                                info.row.original.uuid
                                    ? onClickDelete(info.row.original.uuid)
                                    : null
                            }
                        ></Button>
                    </div>
                ),
            }),
        ],
        [tableData],
    )

    const onClickEdit = (uuid: string) => {
        setOpenModal(true)
        setActiveId(uuid)
    }

    const onClickDelete = (uuid: string) => {
        if (confirm(`Are you sure to delete record with id ${uuid}?`))
            deleteDictionaryMerchant(uuid)
    }

    const closeModal = () => {
        setOpenModal(false)
        setActiveId(undefined)
    }

    useEffect(() => {
        setTableData(dictionarymerchants)
    }, [dictionarymerchants])

    if (isLoading) {
        return (
            <Page>
                <div>Loading...</div>
            </Page>
        )
    }

    return (
        <Page>
            <div className={styles.wrapper}>
                <div className={`${styles.dFlex} ${styles.mb25}`}>
                    <Search
                        value={searchFilter}
                        onChange={(value: string) => setSearchFilter(value)}
                    />
                </div>
                <div className={styles.mb3}>
                    {tableData && (
                        <Table columns={columns} data={tableData} globalFilter={searchFilter} />
                    )}
                </div>
                <div className={styles.flexEnd}>
                    <Button
                        type={'button'}
                        action={'create'}
                        onClickHandler={() => setOpenModal(true)}
                    >
                        New
                    </Button>
                </div>
            </div>

            <Modal header={'Dictionary Merchant'} isOpen={openModal} closeModal={closeModal}>
                {activeId ? (
                    <DictionaryMerchantDetail uuid={activeId} closeModal={closeModal} />
                ) : (
                    <DictionaryMerchantCreateForm closeModal={closeModal} />
                )}
            </Modal>
        </Page>
    )
}
