export enum ContactRole {
    Agent = 'agent',
    Members = 'members',
}

export enum MerchantStatus {
    New = 'new',
    Pending = 'pending',
    Confirmed = 'confirmed',
}

export enum MerchantType {
    LegalEntity = 'legal_entity',
    Corporation = 'corporation',
    LimitedCompany = 'limited_company',
}

export enum Risk {
    High = 'high',
    Medium = 'medium',
    Blacklist = 'blacklist',
    Sanction = 'sanction',
    Terrorist = 'terrorist',
}

export enum RuleStatus {
    Active = 'active',
    Pending = 'pending',
    Inactive = 'inactive',
}

export enum RuleCategory {
    Payer = 'payer',
    Merchant = 'merchant',
}

export enum IpType {
    Ipv4 = 'ipv4',
    Ipv6 = 'ipv6',
}

export enum PayerPaymentMethod {
    Psip = 'PSIP',
    Mobile = 'MOBILE',
}

export enum Currency {
    Euro = 'EUR',
    Dollar = 'USD',
}

export enum TransactionStatus {
    Pending = 'PENDING',
    Canceked = 'CANCELED',
    Expired = 'EXPIRED',
    Confirmed = 'CONFIRMED',
}

export enum PaymentLineType {
    Digital = 'DIGITAL',
    Physical = 'PHYSICAL',
}
