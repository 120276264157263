import styles from '../index.module.scss'
import { useForm } from 'react-hook-form'
import { Button } from '../../button'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import * as transactionApi from '../../../app/services/api/transactionApi'
import { Base64 } from '../../../types/data'

interface TypeProps {
    uuid: string
    inc: number
    closeModal(): void
}

interface FormDataType {
    reason: string
    files: FileList | undefined
}

interface RequestVariables {
    reason: Base64
    data: FormData
}

export const CheckResultForm = ({ uuid, inc, closeModal }: TypeProps) => {
    const initialFormData: FormDataType = {
        reason: '',
        files: undefined,
    }

    const queryClient = useQueryClient()
    const { mutate: createRelease, isLoading } = useMutation(
        ({ reason, data }: RequestVariables) =>
            transactionApi.createRelease(uuid, +inc, reason, data),
        {
            onSuccess: () => {
                toast.success('Transaction result was successfully updated!')
                queryClient.invalidateQueries('transactions')
                closeModal()
            },
            onError: (error: Error) => {
                toast.error(`Something went wrong: ${error.message}`)
            },
        },
    )

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({
        mode: 'onChange',
        defaultValues: initialFormData,
    })

    const onSubmit = ({ reason, files }: FormDataType) => {
        if (files) {
            const formData = new FormData()

            for (let i = 0; i < files?.length; i++) {
                formData.append('files', files[i])
            }

            createRelease({
                reason: btoa(reason),
                data: formData,
            })
        }
    }

    return (
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <label className={`${styles.inputWrapper} ${styles.fullWidth}`}>
                <span className={styles.inputLabel}>Reason</span>
                <textarea
                    required
                    {...register('reason', {
                        required: 'Reason is required!',
                    })}
                    className={styles.inputControl}
                    aria-invalid={errors.files ? 'true' : 'false'}
                ></textarea>

                {errors.reason && (
                    <p role='alert' className={styles.inputError}>
                        {errors.reason?.message}
                    </p>
                )}
            </label>

            <label className={`${styles.inputWrapper} ${styles.fullWidth}`}>
                <span className={styles.inputLabel}>Files</span>
                <input
                    type={'file'}
                    {...register('files')}
                    multiple
                    className={styles.inputControl}
                />
            </label>

            <div className={styles.action}>
                <Button type={'submit'} disabled={isLoading}>
                    Save
                </Button>
            </div>
        </form>
    )
}
