import styles from '../index.module.scss'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useForm } from 'react-hook-form'
import { FixType, IDictionaryPayer } from '../../../types/data'
import * as dictionaryPayerApi from '../../../app/services/api/dictionaryPayerApi'
import { Button } from '../../button'
import { toast } from 'react-toastify'
import { Risk } from '../../../config/enums'

export const DictionaryPayerDetail = ({
    uuid,
    closeModal,
}: {
    uuid: string
    closeModal(): void
}) => {
    const queryClient = useQueryClient()
    const { data } = useQuery(['dictionary-payer', uuid], () => dictionaryPayerApi.get(uuid))
    const { mutate: updateDictionaryPayer, isLoading } = useMutation(
        (dictionaryCountry: IDictionaryPayer) =>
            dictionaryPayerApi.updateDictionaryPayer(dictionaryCountry),
        {
            onSuccess: () => {
                toast.success('Dictionary payer was successfully updated!')
                queryClient.invalidateQueries(['dictionary-payer', uuid])
                queryClient.invalidateQueries(['dictionary-payers'])
                closeModal()
            },
            onError: (error: Error) => {
                toast.error(`Something went wrong: ${error.message}`)
            },
        },
    )

    if (data) {
        return (
            <DictionaryPayerForm
                dictionaryPayer={data}
                isLoading={isLoading}
                onSubmit={updateDictionaryPayer}
            />
        )
    }

    return <div>Loading...</div>
}

interface TypeProps {
    dictionaryPayer: IDictionaryPayer
    isLoading: boolean
    onSubmit: (v: FixType, p: { onSuccess: () => void }) => void
}

const DictionaryPayerForm = ({ dictionaryPayer, isLoading, onSubmit }: TypeProps) => {
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm<IDictionaryPayer>({
        defaultValues: dictionaryPayer,
        mode: 'onBlur',
    })

    return (
        <form
            className={styles.form}
            onSubmit={handleSubmit((values: IDictionaryPayer) =>
                onSubmit(values, { onSuccess: () => reset() }),
            )}
        >
            <label className={`${styles.inputWrapper} ${styles.fullWidth}`}>
                <span className={styles.inputLabel}>UUID</span>
                <input
                    type={'string'}
                    className={styles.inputControl}
                    aria-invalid={errors.uuid ? 'true' : 'false'}
                    {...register('uuid', { required: 'UUID is required!' })}
                />
            </label>

            <label className={`${styles.inputWrapper} ${styles.fullWidth}`}>
                <span className={styles.inputLabel}>Risk</span>
                <select
                    required
                    className={styles.inputControl}
                    aria-invalid={errors.risk ? 'true' : 'false'}
                    {...register('risk', { required: 'Risk is required!' })}
                >
                    {Object.values(Risk).map((item) => (
                        <option key={item} value={item}>
                            {item}
                        </option>
                    ))}
                </select>
                {errors.risk && (
                    <p role='alert' className={styles.inputError}>
                        {errors.risk?.message}
                    </p>
                )}
            </label>

            <label className={`${styles.inputWrapper} ${styles.fullWidth}`}>
                <span className={styles.inputLabel}>Note</span>
                <textarea
                    cols={30}
                    rows={10}
                    {...register('note')}
                    className={styles.inputControl}
                ></textarea>
            </label>

            <div className={styles.action}>
                <Button type={'submit'} disabled={isLoading}>
                    Save
                </Button>
            </div>
        </form>
    )
}
