import { API } from 'aws-amplify'
import { API_NAME } from '../../../config/aws'
import { IDictionaryMerchant } from '../../../types/data'

export const get = async (uuid: string): Promise<IDictionaryMerchant> => {
    const { data } = await API.get(API_NAME, `/dictionary/merchants/${uuid}`, {})
    return data
}

export const getAll = async (): Promise<IDictionaryMerchant[]> => {
    const { data } = await API.get(API_NAME, '/dictionary/merchants', {})
    return data
}

export const createDictionaryMerchant = async (
    body: IDictionaryMerchant,
): Promise<IDictionaryMerchant> => {
    const { data } = await API.post(API_NAME, '/dictionary/merchants', { body })
    return data
}

export const updateDictionaryMerchant = async (
    body: IDictionaryMerchant,
): Promise<IDictionaryMerchant> => {
    const { data } = await API.put(API_NAME, '/dictionary/merchants', { body })
    return data
}

export const deleteDictionaryMerchant = async (uuid: string): Promise<void> => {
    return await API.del(API_NAME, `/dictionary/merchants/${uuid}`, {})
}
