import styles from './index.module.scss'
import logo from './logo.png'
import { Link, NavLink } from 'react-router-dom'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { getLinkPaths } from '../../utils'
import PrivateRoutes from '../../config/privateRoutesConfig'
import { FixType, IPrivateRoute, IRoute } from '../../types/data'
import { cloneElement, JSX, useState } from 'react'
import useOutsideClick from '../../hooks/useOutsideClick'

interface DropdownTypeProps {
    trigger: FixType
    menu: JSX.Element[]
}

const Dropdown = ({ trigger, menu }: DropdownTypeProps) => {
    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(!open)
    }
    const ref = useOutsideClick(() => setOpen(false))

    return (
        <div className={styles.dropdown}>
            {cloneElement(trigger, {
                ref: ref,
                onClick: handleOpen,
            })}
            {open ? (
                <ul className={styles.menu}>
                    {menu.map((menuItem, index) => (
                        <li key={index} className={styles.menuItem}>
                            {menuItem}
                        </li>
                    ))}
                </ul>
            ) : null}
        </div>
    )
}

export const Header = () => {
    const { signOut, user } = useAuthenticator((context) => [context.signOut])
    const groups = user?.getSignInUserSession()?.getAccessToken()?.payload['cognito:groups']

    let navLinks: IRoute[] = []
    if (groups) {
        navLinks = getLinkPaths(PrivateRoutes, groups)
    }

    const Menu = () => (
        <>
            {navLinks
                .filter(({ title }: IPrivateRoute) => title)
                .map(({ path, title, submenu }: IPrivateRoute) => {
                    if (path) {
                        return (
                            <NavLink
                                key={path}
                                to={path}
                                className={({ isActive }) =>
                                    `${styles.navLink} ${isActive ? styles.active : ''}`
                                }
                            >
                                {title}
                            </NavLink>
                        )
                    } else if (submenu?.length) {
                        return (
                            <Dropdown
                                key={title}
                                trigger={
                                    <span className={styles.navLink}>
                                        {title}
                                        <span className={styles.downCaret}></span>
                                    </span>
                                }
                                menu={submenu.map(({ title, path }: IPrivateRoute) => {
                                    return (
                                        <NavLink
                                            key={path}
                                            to={path || ''}
                                            className={({ isActive }) =>
                                                `${styles.navLink} ${isActive ? styles.active : ''}`
                                            }
                                        >
                                            {title}
                                        </NavLink>
                                    )
                                })}
                            />
                        )
                    }
                })}
        </>
    )

    return (
        <header className={styles.header}>
            <div className='container'>
                <div className={styles.headerContent}>
                    <Link to={'/'}>
                        <img src={logo} alt={'h3llo logo'} className={styles.headerLogo} />
                    </Link>
                    <nav className={styles.nav}>
                        <Menu />
                        <div className={styles.logout} onClick={() => signOut()}></div>
                    </nav>
                </div>
            </div>
        </header>
    )
}
