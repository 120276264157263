import { useNavigate, useParams } from 'react-router-dom'
import { Page } from '../../components/page'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import * as ruleApi from '../../app/services/api/ruleApi'
import { useEffect, useState } from 'react'
import { RuleCategory } from '../../config/enums'
import { read, write } from 'xlsx'
import styles from './index.module.scss'
import { Spreadsheet } from '../../components/spreadsheet'
import { Base64, createRuleVariables, FixType } from '../../types/data'
import { stox, xtos } from '../../utils'
import { toast } from 'react-toastify'

type TypeParams = {
    category: RuleCategory
    id: string
}

export const RuleExcelPage = () => {
    const navigate = useNavigate()
    const { category, id } = useParams<TypeParams>()
    const [spreadsheetData, setSpreadsheetData] = useState<FixType[]>()

    const { data: rule, isLoading: isRuleLoading } = useQuery(
        ['rule-data', category, id],
        () => ruleApi.get(category as RuleCategory, id || ''),
        {
            staleTime: Infinity,
            onError: (error: Error) => {
                toast.error(`Something went wrong: ${error.message}`)
            },
        },
    )

    const queryClient = useQueryClient()
    const { mutate: createRule, isLoading: isCreateRuleLoading } = useMutation(
        ({ category, uuid, data }: createRuleVariables) =>
            ruleApi.createRule(category, { uuid, data }),
        {
            onSuccess: (data) => {
                toast.success(`Rule revision ${data.revision} was successfully created!`)
                queryClient.invalidateQueries('rules')
                navigate('/rules')
            },
            onError: (error: Error) => {
                toast.error(`Something went wrong: ${error.message}`)
            },
        },
    )

    useEffect(() => {
        if (rule?.data) {
            const wb = read(rule.data, { type: 'base64' })
            setSpreadsheetData(stox(wb))
        }
    }, [rule])

    const saveHandler = (data: FixType) => {
        const base64: Base64 = write(xtos(data), { type: 'base64' })

        if (category) {
            createRule({
                uuid: rule?.uuid,
                category,
                data: base64,
            })
        }
    }

    if (isRuleLoading) {
        return (
            <Page>
                <div>Loading...</div>
            </Page>
        )
    }

    return (
        <Page>
            <div className={styles.wrapper}>
                {spreadsheetData && (
                    <>
                        <h1>
                            Rule revision {rule?.revision}: {rule?.uuid}
                        </h1>
                        <div className={styles.table}>
                            <Spreadsheet
                                data={spreadsheetData}
                                options={{
                                    mode: 'edit',
                                    showToolbar: false,
                                    showContextmenu: true,
                                }}
                                exportData={{
                                    cb: saveHandler,
                                    isLoading: isCreateRuleLoading,
                                }}
                            />
                        </div>
                    </>
                )}
            </div>
        </Page>
    )
}
