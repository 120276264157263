import { FixType, IPrivateRoute } from '../types/data'
import XLSX from 'xlsx'

export const getAllowedRoutes = (routes: IPrivateRoute[], allowedRoles: string[]) => {
    const arr: IPrivateRoute[] = []
    routes.forEach((route) => {
        if (route.permission?.some((p: string) => allowedRoles.includes(p))) {
            if (route.submenu?.length && !route.path) {
                arr.push(...getAllowedRoutes(route.submenu, allowedRoles))
            } else {
                arr.push(route)
            }
        }
    })

    return arr
}

export const getLinkPaths = (routes: IPrivateRoute[], allowedRoles: string[]) => {
    return routes.filter((route) => {
        if (route.title && route.permission?.some((p: string) => allowedRoles.includes(p))) {
            if (route.submenu?.length) {
                route.submenu = getLinkPaths(route.submenu, allowedRoles)
            }
            return true
        }
    })
}

export const isFileValidByExt = (file: File, allowedExt: string[]): boolean => {
    const extension = file.name.split('.').pop()

    return !!extension && allowedExt.includes(extension)
}

export const formatDate = (date: Date): string => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2, '0')

    return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`
}

/**
 * Converts data from SheetJS to x-spreadsheet
 *
 * @param  {Object} wb SheetJS workbook object
 *
 * @returns {Object[]} An x-spreadsheet data
 */
export const stox = (wb: FixType) => {
    const out: FixType[] = []
    wb.SheetNames.forEach((name: string) => {
        const o: FixType = { name: name, rows: {}, merges: [] }
        const ws = wb.Sheets[name]
        const range = XLSX.utils.decode_range(ws['!ref'])
        // sheet_to_json will lost empty row and col at begin as default
        range.s = { r: 0, c: 0 }
        const aoa = XLSX.utils.sheet_to_json(ws, {
            raw: false,
            header: 1,
            range: range,
        })

        aoa.forEach((r: FixType, i: number) => {
            const cells: FixType = {}
            r.forEach((c: FixType, j: number) => {
                cells[j] = { text: c }

                const cellRef = XLSX.utils.encode_cell({ r: i, c: j })

                if (ws[cellRef] != null && ws[cellRef].f != null) {
                    cells[j].text = '=' + ws[cellRef].f
                }
            })
            o.rows[i] = { cells: cells }
        })

        o.merges = [] as []
        ;(ws['!merges'] || []).forEach((merge: FixType, i: number) => {
            // Needed to support merged cells with empty content
            if (o.rows[merge.s.r] == null) {
                o.rows[merge.s.r] = { cells: {} }
            }
            if (o.rows[merge.s.r].cells[merge.s.c] == null) {
                o.rows[merge.s.r].cells[merge.s.c] = {}
            }

            o.rows[merge.s.r].cells[merge.s.c].merge = [
                merge.e.r - merge.s.r,
                merge.e.c - merge.s.c,
            ]

            o.merges[i] = XLSX.utils.encode_range(merge)
        })

        out.push(o)
    })

    return out
}

/**
 * Converts data from x-spreadsheet to SheetJS
 *
 * @param  {Object[]} sdata An x-spreadsheet data object
 *
 * @returns {Object} A SheetJS workbook object
 */

export const xtos = (sdata: FixType) => {
    const out = XLSX.utils.book_new()
    sdata.forEach((xws: FixType) => {
        const ws: FixType = {}
        const rowobj = xws.rows
        const minCoord = { r: 0, c: 0 },
            maxCoord = { r: 0, c: 0 }
        for (let ri = 0; ri < rowobj.len; ++ri) {
            const row = rowobj[ri]
            if (!row) continue

            Object.keys(row.cells).forEach(function (k) {
                const idx = +k
                if (isNaN(idx)) return

                const lastRef = XLSX.utils.encode_cell({ r: ri, c: idx })
                if (ri > maxCoord.r) maxCoord.r = ri
                if (idx > maxCoord.c) maxCoord.c = idx

                let cellText = row.cells[k].text,
                    type = 's'
                if (!cellText) {
                    cellText = ''
                    type = 'z'
                } else if (!isNaN(Number(cellText))) {
                    cellText = Number(cellText)
                    type = 'n'
                } else if (
                    cellText.toLowerCase() === 'true' ||
                    cellText.toLowerCase() === 'false'
                ) {
                    cellText = Boolean(cellText)
                    type = 'b'
                }

                ws[lastRef] = { v: cellText, t: type }

                if (type === 's' && cellText[0] === '=') {
                    ws[lastRef].f = cellText.slice(1)
                }

                if (row.cells[k].merge != null) {
                    if (ws['!merges'] == null) ws['!merges'] = []

                    ws['!merges'].push({
                        s: { r: ri, c: idx },
                        e: {
                            r: ri + row.cells[k].merge[0],
                            c: idx + row.cells[k].merge[1],
                        },
                    })
                }
            })
        }
        ws['!ref'] = minCoord
            ? XLSX.utils.encode_range({
                  s: minCoord,
                  e: maxCoord,
              })
            : 'A1'

        XLSX.utils.book_append_sheet(out, ws, xws.name)
    })

    return out
}
