import { Page } from '../../components/page'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import * as merchantApi from '../../app/services/api/merchantApi'
import { useEffect, useMemo, useState } from 'react'
import styles from '../../modules/table-pages/index.module.scss'
import { Search } from '../../components/search'
import { Table } from '../../components/table'
import { IMerchant } from '../../types/data'
import { createColumnHelper } from '@tanstack/react-table'
import { Modal } from '../../components/modal'
import { Button } from '../../components/button'
import { MerchantCreateForm } from '../../components/forms/merchant/create-form'
import { MerchantDetail } from '../../components/forms/merchant/edit-form'
import { toast } from 'react-toastify'

export const MerchantsPage = () => {
    const queryClient = useQueryClient()
    const { isLoading, data: merchants } = useQuery('merchants', merchantApi.getAll, {
        onError: (error: Error) => {
            toast.error(`Something went wrong: ${error.message}`)
        },
    })
    const { mutate: deleteMerchant } = useMutation((id: string) => merchantApi.deleteMerchant(id), {
        onSuccess: () => {
            toast.success('Merchant was successfully deleted!')
            queryClient.invalidateQueries(['merchants'])
        },
        onError: (error: Error) => {
            toast.error(`Something went wrong: ${error.message}`)
        },
    })

    const [tableData, setTableData] = useState<IMerchant[] | undefined>([])
    const [searchFilter, setSearchFilter] = useState<string>('')
    const [activeId, setActiveId] = useState<string>('')
    const [openModal, setOpenModal] = useState<boolean>(false)

    const columnHelper = createColumnHelper<IMerchant>()
    const columns = useMemo(
        () => [
            columnHelper.accessor('businessName', {
                header: 'Business Name',
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor('publicBusinessName', {
                header: 'Public Business Name',
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor('businessIdentifier', {
                header: 'Business Identifier',
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor('url', {
                header: 'Url',
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor('phoneNumber', {
                header: 'Phone Number',
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor('email', {
                header: 'Email',
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor('uuid', {
                header: 'ID',
                cell: (info) => info.getValue(),
            }),
            columnHelper.display({
                id: 'actions',
                header: '',
                cell: (info) => (
                    <div className={`${styles.dFlex} ${styles.g1}`}>
                        <Button
                            type={'button'}
                            action={'edit'}
                            onClickHandler={() => onClickEdit(info.row.original.uuid)}
                        ></Button>
                        <Button
                            type={'button'}
                            action={'delete'}
                            onClickHandler={() => onClickDelete(info.row.original.uuid)}
                        ></Button>
                    </div>
                ),
            }),
        ],
        [tableData],
    )

    const onClickEdit = (id: string) => {
        setOpenModal(true)
        setActiveId(id)
    }

    const onClickDelete = (id: string) => {
        if (confirm(`Are you sure to delete record with id ${id}?`)) deleteMerchant(id)
    }

    const closeModal = () => {
        setOpenModal(false)
        setActiveId('')
    }

    useEffect(() => {
        setTableData(merchants)
    }, [merchants])

    if (isLoading) {
        return (
            <Page>
                <div>Loading...</div>
            </Page>
        )
    }

    return (
        <Page>
            <div className={styles.wrapper}>
                <div className={`${styles.dFlex} ${styles.mb25}`}>
                    <Search
                        value={searchFilter}
                        onChange={(value: string) => setSearchFilter(value)}
                    />
                </div>
                <div className={styles.mb3}>
                    {tableData && (
                        <Table columns={columns} data={tableData} globalFilter={searchFilter} />
                    )}
                </div>
                <div className={styles.flexEnd}>
                    <Button
                        type={'button'}
                        action={'create'}
                        disabled={true}
                        onClickHandler={() => setOpenModal(true)}
                    >
                        New
                    </Button>
                </div>
            </div>

            <Modal header={'Merchant'} isOpen={openModal} closeModal={closeModal}>
                {activeId ? (
                    <MerchantDetail id={activeId} closeModal={closeModal} />
                ) : (
                    <MerchantCreateForm />
                )}
            </Modal>
        </Page>
    )
}
