import styles from '../index.module.scss'
import { useForm } from 'react-hook-form'
import { IDictionaryMerchant } from '../../../types/data'
import { Button } from '../../button'
import { useMutation, useQueryClient } from 'react-query'
import * as dictionaryMerchantApi from '../../../app/services/api/dictionaryMerchantApi'
import { toast } from 'react-toastify'
import { Risk } from '../../../config/enums'

interface TypeProps {
    closeModal(): void
}

export const DictionaryMerchantCreateForm = ({ closeModal }: TypeProps) => {
    const dictionaryMerchant: IDictionaryMerchant = {
        uuid: '',
        risk: Risk.Sanction,
        note: '',
        data: null,
    }

    const queryClient = useQueryClient()
    const { mutate: createDictionaryMerchant, isLoading } = useMutation(
        (dictionaryMerchant: IDictionaryMerchant) =>
            dictionaryMerchantApi.createDictionaryMerchant(dictionaryMerchant),
        {
            onSuccess: () => {
                toast.success('Dictionary merchant was successfully created!')
                queryClient.invalidateQueries('dictionary-merchants')
                closeModal()
            },
            onError: (error: Error) => {
                toast.error(`Something went wrong: ${error.message}`)
            },
        },
    )
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({
        mode: 'onBlur',
        defaultValues: dictionaryMerchant,
    })

    const onSubmit = (data: IDictionaryMerchant) => createDictionaryMerchant(data)

    return (
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <label className={`${styles.inputWrapper} ${styles.fullWidth}`}>
                <span className={styles.inputLabel}>UUID</span>
                <input
                    type={'string'}
                    className={styles.inputControl}
                    aria-invalid={errors.uuid ? 'true' : 'false'}
                    {...register('uuid', { required: 'UUID is required!' })}
                />
            </label>

            <label className={`${styles.inputWrapper} ${styles.fullWidth}`}>
                <span className={styles.inputLabel}>Risk</span>
                <select
                    required
                    className={styles.inputControl}
                    aria-invalid={errors.risk ? 'true' : 'false'}
                    {...register('risk', { required: 'Risk is required!' })}
                >
                    {Object.values(Risk).map((item) => (
                        <option key={item} value={item}>
                            {item}
                        </option>
                    ))}
                </select>
                {errors.risk && (
                    <p role='alert' className={styles.inputError}>
                        {errors.risk?.message}
                    </p>
                )}
            </label>

            <label className={`${styles.inputWrapper} ${styles.fullWidth}`}>
                <span className={styles.inputLabel}>Note</span>
                <textarea
                    cols={30}
                    rows={10}
                    {...register('note')}
                    className={styles.inputControl}
                ></textarea>
            </label>

            <div className={styles.action}>
                <Button type={'submit'} disabled={isLoading}>
                    Save
                </Button>
            </div>
        </form>
    )
}
