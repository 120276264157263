import { Page } from '../../components/page'
import styles from '../../modules/table-pages/index.module.scss'
import { Search } from '../../components/search'
import { Table } from '../../components/table'
import { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import * as transactionApi from '../../app/services/api/transactionApi'
import { createColumnHelper } from '@tanstack/react-table'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import { ISearchTransaction } from '../../types/data'
import { useNavigate } from 'react-router-dom'
import DateRangePicker, { DateRange } from 'rsuite/DateRangePicker'
import 'rsuite/dist/rsuite.css'
import { afterToday } from 'rsuite/cjs/DateRangePicker/disabledDateUtils'

const defaultDateRangeValues: DateRange = [
    dayjs().startOf('day').toDate(),
    dayjs().endOf('day').toDate(),
]

export const TransactionsPage = () => {
    const navigate = useNavigate()

    const [tableData, setTableData] = useState<ISearchTransaction[] | undefined>([])
    const [searchFilter, setSearchFilter] = useState<string>('')
    const [dateRangeValue, setDateRangeValue] = useState<DateRange>(defaultDateRangeValues)

    const { isLoading, data: transactions } = useQuery(
        ['transactions', dateRangeValue],
        () => {
            const [startDate, endDate] = dateRangeValue
            return transactionApi.getAll(startDate.toISOString(), endDate.toISOString())
        },
        {
            onError: (error: Error) => {
                toast.error(`Something went wrong: ${error.message}`)
            },
        },
    )

    const columnHelper = createColumnHelper<ISearchTransaction>()
    const columns = useMemo(
        () => [
            columnHelper.accessor('uuid', {
                header: 'UUID',
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor('inc', {
                header: 'Inc',
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor('status', {
                header: 'Status',
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor('successful', {
                header: 'Successful',
                cell: (info) => info.getValue()?.toString(),
            }),
            columnHelper.accessor('flag', {
                header: 'Flag',
                cell: (info) => info.getValue()?.toString(),
            }),
            columnHelper.accessor('released', {
                header: 'Released',
                cell: (info) => info.getValue()?.toString(),
            }),
            columnHelper.accessor('creationDate', {
                header: 'Creation Date',
                cell: (info) => dayjs(info.getValue()).format('YYYY/MM/DD H:mm:ss'),
            }),
        ],
        [tableData],
    )

    useEffect(() => {
        setTableData(transactions)
    }, [transactions])

    const onTransactionClick = ({ uuid, inc }: ISearchTransaction) => {
        navigate(`/transactions/${uuid}/${inc}`)
    }

    const onDateRangeChange = (value: DateRange | null) => {
        if (value) setDateRangeValue(value)
    }

    return (
        <Page>
            <div className={styles.wrapper}>
                <div className={`${styles.dFlex} ${styles.mb25}`}>
                    <DateRangePicker
                        value={dateRangeValue}
                        defaultValue={defaultDateRangeValues}
                        onChange={onDateRangeChange}
                        format='yyyy-MM-dd HH:mm:ss'
                        shouldDisableDate={afterToday()}
                        cleanable={false}
                        disabled={isLoading}
                    />
                    <Search
                        value={searchFilter}
                        onChange={(value: string) => setSearchFilter(value)}
                        disabled={isLoading}
                    />
                </div>
                <div className={styles.mb3}>
                    {isLoading
                        ? 'Loading...'
                        : tableData && (
                              <Table
                                  columns={columns}
                                  data={tableData}
                                  globalFilter={searchFilter}
                                  sortBy={[{ id: 'creationDate', desc: false }]}
                                  onRowClick={onTransactionClick}
                              />
                          )}
                </div>
            </div>
        </Page>
    )
}
