import { useEffect, useRef } from 'react'
import { FixType } from '../types/data'

const useOutsideClick = (callback: FixType) => {
    const ref = useRef<FixType>()

    useEffect(() => {
        const handleClick = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback()
            }
        }

        document.addEventListener('click', handleClick, true)

        return () => {
            document.removeEventListener('click', handleClick, true)
        }
    }, [ref])

    return ref
}

export default useOutsideClick
