import React from 'react'
import ReactDOM from 'react-dom/client'
import '@aws-amplify/ui-react/styles.css'
import './index.scss'
import reportWebVitals from './reportWebVitals'
import App from './App'
import { Amplify } from 'aws-amplify'
import { Authenticator } from '@aws-amplify/ui-react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { awsConfig } from './config/aws'

Amplify.configure(awsConfig)

const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <Authenticator.Provider>
            <QueryClientProvider client={queryClient}>
                <App />
            </QueryClientProvider>
        </Authenticator.Provider>
    </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
