import { API } from 'aws-amplify'
import { API_NAME } from '../../../config/aws'
import { IDictionaryPep } from '../../../types/data'

export const get = async (uuid: string): Promise<IDictionaryPep> => {
    const { data } = await API.get(API_NAME, `/dictionary/pep/${uuid}`, {})
    return data
}

export const getAll = async (): Promise<IDictionaryPep[]> => {
    const { data } = await API.get(API_NAME, '/dictionary/pep', {})
    return data
}

export const createDictionaryPep = async (body: IDictionaryPep): Promise<IDictionaryPep> => {
    const { data } = await API.post(API_NAME, '/dictionary/pep', { body })
    return data
}

export const updateDictionaryPep = async (body: IDictionaryPep): Promise<IDictionaryPep> => {
    const { data } = await API.put(API_NAME, '/dictionary/pep', { body })
    return data
}

export const deleteDictionaryPep = async (uuid: string): Promise<void> => {
    return await API.del(API_NAME, `/dictionary/pep/${uuid}`, {})
}
