import styles from './index.module.scss'
import { ReactNode, useEffect } from 'react'
import { createPortal } from 'react-dom'

interface TypeProps {
    header: string
    isOpen: boolean
    closeModal(): void
    children: ReactNode
}

export const Modal = ({ header, isOpen, closeModal, children }: TypeProps) => {
    useEffect(() => {
        document.body.style.overflow = isOpen ? 'hidden' : 'unset'
    }, [isOpen])

    return (
        <>
            {isOpen &&
                createPortal(
                    <>
                        <div className={styles.darkBG} onClick={closeModal} />
                        <div className={styles.centered}>
                            <div className={styles.modal}>
                                <div className={styles.modalHeader}>
                                    <h5 className={styles.heading}>{header}</h5>
                                </div>
                                <button className={styles.closeBtn} onClick={closeModal}>
                                    ✖
                                </button>
                                <div className={styles.modalContent}>{children}</div>
                            </div>
                        </div>
                    </>,
                    document.body,
                )}
        </>
    )
}
