import { Page } from '../../components/page'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import * as transactionApi from '../../app/services/api/transactionApi'
import { toast } from 'react-toastify'
import formStyles from '../../components/forms/index.module.scss'
import styles from './index.module.scss'
import dayjs from 'dayjs'
import { IFailureDetails, IPaymentLine, IReceiver } from '../../types/data'
import { Fragment, useState } from 'react'
import { Button } from '../../components/button'
import { Modal } from '../../components/modal'
import { CheckResultForm } from '../../components/forms/transaction/check-result-form'

interface InputTypeProps {
    label: string
    type: 'text' | 'number'
    value: string | number
    title?: string
}

const Input = ({ label, type, value, title }: InputTypeProps) => {
    return (
        <label className={`${formStyles.inputWrapper} ${formStyles.fullWidth}`}>
            <span className={formStyles.inputLabel}>{label}</span>
            <input
                value={value}
                type={type}
                className={formStyles.inputControl}
                disabled
                title={title || ''}
            />
        </label>
    )
}

export const TransactionPage = () => {
    const navigate = useNavigate()
    const { uuid, inc } = useParams()
    const [openModal, setOpenModal] = useState<boolean>(false)
    const { data: fullTransaction, isLoading } = useQuery(
        ['transaction', uuid, inc],
        () =>
            uuid && inc
                ? transactionApi.get(uuid, +inc)
                : Promise.reject(new Error('Uuid and inc is required params!')),
        {
            staleTime: Infinity,
            onError: (error: Error) => {
                toast.error(`Something went wrong: ${error.message}`)
            },
        },
    )

    const onOverrideBtnClick = () => {
        setOpenModal(true)
    }

    if (isLoading) {
        return <Page>Loading...</Page>
    }

    return (
        <Page>
            <div className={styles.wrapper}>
                {fullTransaction && (
                    <>
                        <div>
                            <Button type={'button'} onClickHandler={() => navigate(-1)}>
                                &#8592; Back
                            </Button>
                        </div>

                        <h1>Transaction details</h1>

                        <form className={formStyles.form}>
                            <fieldset className={formStyles.fieldset}>
                                <legend
                                    className={`${formStyles.legend} ${styles.checkResultHeader}`}
                                >
                                    <h3>Check result details</h3>
                                    {fullTransaction.checkResult &&
                                        !fullTransaction.checkResult.successful &&
                                        !fullTransaction.checkResult.released && (
                                            <Button
                                                type={'button'}
                                                onClickHandler={onOverrideBtnClick}
                                            >
                                                Release
                                            </Button>
                                        )}
                                </legend>
                                <Input
                                    type={'text'}
                                    label={'UUID'}
                                    value={fullTransaction.checkResult?.uuid || ''}
                                />
                                <Input
                                    type={'number'}
                                    label={'Inc'}
                                    value={fullTransaction.checkResult?.inc || ''}
                                />
                                <Input
                                    type={'text'}
                                    label={'Successful'}
                                    value={
                                        fullTransaction.checkResult
                                            ? fullTransaction.checkResult.successful
                                                ? 'True'
                                                : 'False'
                                            : ''
                                    }
                                />
                                <Input
                                    type={'text'}
                                    label={'Status'}
                                    value={
                                        fullTransaction.checkResult
                                            ? fullTransaction.checkResult.status &&
                                              fullTransaction.checkResult.status == 'C'
                                                ? 'Completed'
                                                : fullTransaction.checkResult.status == 'P'
                                                ? 'Processed'
                                                : 'Failed'
                                            : ''
                                    }
                                />

                                <Input
                                    type={'text'}
                                    label={'Creation date'}
                                    value={
                                        fullTransaction.checkResult?.creationDate
                                            ? dayjs(
                                                  fullTransaction.checkResult.creationDate,
                                              ).format('YYYY/MM/DD H:mm:ss')
                                            : ''
                                    }
                                />

                                <Input
                                    type={'text'}
                                    label={'Flag'}
                                    value={
                                        fullTransaction.checkResult
                                            ? fullTransaction.checkResult.flag
                                                ? 'True'
                                                : 'False'
                                            : ''
                                    }
                                />

                                {!!fullTransaction.checkResult?.failures?.length && (
                                    <fieldset className={formStyles.fieldset}>
                                        <legend className={formStyles.legend}>
                                            <h4>Failures:</h4>
                                        </legend>
                                        {fullTransaction.checkResult.failures.map(
                                            (failure: IFailureDetails, index) => (
                                                <Fragment key={index}>
                                                    <Input
                                                        type={'text'}
                                                        label={'Type'}
                                                        value={failure.type || ''}
                                                    />

                                                    <Input
                                                        type={'text'}
                                                        label={'Key'}
                                                        value={failure.key || ''}
                                                    />

                                                    {failure.messages.length &&
                                                        failure.messages.map((message, index) => (
                                                            <textarea
                                                                key={index}
                                                                value={message}
                                                                disabled
                                                            ></textarea>
                                                        ))}
                                                </Fragment>
                                            ),
                                        )}
                                    </fieldset>
                                )}

                                <Input
                                    type={'text'}
                                    label={'Released'}
                                    value={
                                        fullTransaction.checkResult
                                            ? fullTransaction.checkResult?.released
                                                ? 'True'
                                                : 'False'
                                            : ''
                                    }
                                />

                                <Input
                                    type={'text'}
                                    label={'Reason'}
                                    value={fullTransaction.checkResult?.reason || ''}
                                />

                                <Input
                                    type={'text'}
                                    label={'User id'}
                                    value={fullTransaction.checkResult?.userid || ''}
                                />

                                <Input
                                    type={'text'}
                                    label={'Release date'}
                                    value={
                                        fullTransaction.checkResult?.releaseDate
                                            ? dayjs(fullTransaction.checkResult.releaseDate).format(
                                                  'YYYY/MM/DD H:mm:ss',
                                              )
                                            : ''
                                    }
                                />
                            </fieldset>

                            <fieldset className={formStyles.fieldset}>
                                <legend className={formStyles.legend}>
                                    <h3>Transaction</h3>
                                </legend>
                                <Input
                                    type={'text'}
                                    label={'Uuid'}
                                    value={fullTransaction.transaction.uuid || ''}
                                />

                                <Input
                                    type={'number'}
                                    label={'Inc'}
                                    value={fullTransaction.transaction.inc || ''}
                                />

                                <Input
                                    type={'text'}
                                    label={'Created at'}
                                    value={
                                        fullTransaction.transaction.createdAt
                                            ? dayjs(fullTransaction.transaction.createdAt).format(
                                                  'YYYY/MM/DD H:mm:ss',
                                              )
                                            : ''
                                    }
                                />

                                <Input
                                    type={'text'}
                                    label={'Updated at'}
                                    value={
                                        fullTransaction.transaction.updatedAt
                                            ? dayjs(fullTransaction.transaction.updatedAt).format(
                                                  'YYYY/MM/DD H:mm:ss',
                                              )
                                            : ''
                                    }
                                />

                                <Input
                                    type={'text'}
                                    label={'Expires at'}
                                    value={
                                        fullTransaction.transaction.expiresAt
                                            ? dayjs(fullTransaction.transaction.expiresAt).format(
                                                  'YYYY/MM/DD H:mm:ss',
                                              )
                                            : ''
                                    }
                                />

                                {fullTransaction.transaction.payer && (
                                    <fieldset className={formStyles.fieldset}>
                                        <legend className={formStyles.legend}>
                                            <h4>Payer:</h4>
                                        </legend>

                                        <Input
                                            type={'text'}
                                            label={'Uuid'}
                                            value={fullTransaction.transaction.payer.uuid || ''}
                                        />

                                        <Input
                                            type={'text'}
                                            label={'Payment method'}
                                            value={
                                                fullTransaction.transaction.payer.paymentMethod ||
                                                ''
                                            }
                                        />

                                        <Input
                                            type={'text'}
                                            label={'Payment method identifier'}
                                            value={
                                                fullTransaction.transaction.payer
                                                    .paymentMethodIdentifier || ''
                                            }
                                        />

                                        <Input
                                            type={'text'}
                                            label={'State'}
                                            value={fullTransaction.transaction.payer.state || ''}
                                        />

                                        {fullTransaction.transaction.payer.accountPISP && (
                                            <fieldset className={formStyles.fieldset}>
                                                <Input
                                                    type={'text'}
                                                    label={'Iban'}
                                                    value={
                                                        fullTransaction.transaction.payer
                                                            .accountPISP.iban || ''
                                                    }
                                                />

                                                <Input
                                                    type={'text'}
                                                    label={'Bic'}
                                                    value={
                                                        fullTransaction.transaction.payer
                                                            .accountPISP.bic || ''
                                                    }
                                                />

                                                <Input
                                                    type={'text'}
                                                    label={'Owner name'}
                                                    value={
                                                        fullTransaction.transaction.payer
                                                            .accountPISP.ownerName || ''
                                                    }
                                                />

                                                <Input
                                                    type={'text'}
                                                    label={'Country'}
                                                    value={
                                                        fullTransaction.transaction.payer
                                                            .accountPISP.country || ''
                                                    }
                                                />
                                            </fieldset>
                                        )}

                                        {fullTransaction.transaction.payer.accountMobile && (
                                            <fieldset className={formStyles.fieldset}>
                                                <Input
                                                    type={'text'}
                                                    label={'Iban'}
                                                    value={
                                                        fullTransaction.transaction.payer
                                                            .accountMobile.phone || ''
                                                    }
                                                />

                                                <Input
                                                    type={'text'}
                                                    label={'Country'}
                                                    value={
                                                        fullTransaction.transaction.payer
                                                            .accountMobile.country || ''
                                                    }
                                                />
                                            </fieldset>
                                        )}

                                        <Input
                                            type={'text'}
                                            label={'IP'}
                                            value={fullTransaction.transaction.payer.ip || ''}
                                        />

                                        <Input
                                            type={'text'}
                                            label={'Country'}
                                            value={fullTransaction.transaction.payer.country || ''}
                                        />
                                    </fieldset>
                                )}

                                {!!fullTransaction.transaction.receivers?.length && (
                                    <fieldset className={formStyles.fieldset}>
                                        <legend className={formStyles.legend}>
                                            <h4>Receivers:</h4>
                                        </legend>
                                        {fullTransaction.transaction.receivers.map(
                                            (receiver: IReceiver, index) => (
                                                <Fragment key={index}>
                                                    <Input
                                                        type={'text'}
                                                        label={'Merchant uuid'}
                                                        value={receiver.merchantUuid || ''}
                                                    />

                                                    <Input
                                                        type={'text'}
                                                        label={'Merchant reference'}
                                                        value={receiver.merchantReference || ''}
                                                    />

                                                    <fieldset className={formStyles.fieldset}>
                                                        <legend className={formStyles.legend}>
                                                            <h4>Account:</h4>
                                                        </legend>
                                                        <Input
                                                            type={'text'}
                                                            label={'Uuid'}
                                                            value={receiver.account.uuid || ''}
                                                        />

                                                        <Input
                                                            type={'text'}
                                                            label={'Iban'}
                                                            value={receiver.account.iban || ''}
                                                        />

                                                        <Input
                                                            type={'text'}
                                                            label={'Bic'}
                                                            value={receiver.account.bic || ''}
                                                        />

                                                        <Input
                                                            type={'text'}
                                                            label={'Owner name'}
                                                            value={receiver.account.ownerName || ''}
                                                        />

                                                        <Input
                                                            type={'text'}
                                                            label={'Owner email'}
                                                            value={
                                                                receiver.account.ownerEmail || ''
                                                            }
                                                        />

                                                        <Input
                                                            type={'text'}
                                                            label={'Locale'}
                                                            value={receiver.account.locale || ''}
                                                        />
                                                    </fieldset>

                                                    <Input
                                                        type={'number'}
                                                        label={'Amount'}
                                                        value={receiver.amount || ''}
                                                    />
                                                </Fragment>
                                            ),
                                        )}
                                    </fieldset>
                                )}

                                <Input
                                    type={'number'}
                                    label={'Total amount'}
                                    value={fullTransaction.transaction.totalAmount || ''}
                                />

                                <Input
                                    type={'text'}
                                    label={'Currency'}
                                    value={fullTransaction.transaction.currency || ''}
                                />

                                <Input
                                    type={'text'}
                                    label={'Description'}
                                    value={fullTransaction.transaction.description || ''}
                                />

                                <Input
                                    type={'text'}
                                    label={'Redirect url'}
                                    value={fullTransaction.transaction.redirectUrl || ''}
                                />

                                <Input
                                    type={'text'}
                                    label={'Status'}
                                    value={fullTransaction.transaction.status || ''}
                                />

                                {!!fullTransaction.transaction.paymentLineItems?.length && (
                                    <fieldset className={formStyles.fieldset}>
                                        <legend className={formStyles.legend}>
                                            <h4>Payment line items:</h4>
                                        </legend>
                                        {fullTransaction.transaction.paymentLineItems.map(
                                            (paymentLineItem: IPaymentLine, index) => (
                                                <Fragment key={index}>
                                                    <Input
                                                        type={'text'}
                                                        label={'Uuid'}
                                                        value={paymentLineItem.uuid || ''}
                                                    />

                                                    <Input
                                                        type={'text'}
                                                        label={'Created at'}
                                                        value={
                                                            paymentLineItem.createdAt
                                                                ? dayjs(
                                                                      paymentLineItem.createdAt,
                                                                  ).format('YYYY/MM/DD H:mm:ss')
                                                                : ''
                                                        }
                                                    />

                                                    <Input
                                                        type={'text'}
                                                        label={'Product code'}
                                                        value={paymentLineItem.productCode || ''}
                                                    />

                                                    <Input
                                                        type={'text'}
                                                        label={'Name'}
                                                        value={paymentLineItem.name || ''}
                                                    />

                                                    <Input
                                                        type={'number'}
                                                        label={'Count'}
                                                        value={paymentLineItem.count || ''}
                                                    />

                                                    <Input
                                                        type={'number'}
                                                        label={'Unit price'}
                                                        value={paymentLineItem.unitPrice || ''}
                                                    />

                                                    <Input
                                                        type={'number'}
                                                        label={'Total price'}
                                                        value={paymentLineItem.totalPrice || ''}
                                                    />

                                                    <Input
                                                        type={'text'}
                                                        label={'Type'}
                                                        value={paymentLineItem.type || ''}
                                                    />
                                                </Fragment>
                                            ),
                                        )}
                                    </fieldset>
                                )}
                            </fieldset>
                        </form>
                    </>
                )}
            </div>

            {fullTransaction?.checkResult &&
                uuid &&
                inc &&
                !fullTransaction.checkResult.successful &&
                !fullTransaction.checkResult.released && (
                    <Modal
                        header={`Release for ${fullTransaction.transaction.uuid}`}
                        isOpen={openModal}
                        closeModal={() => setOpenModal(false)}
                    >
                        <CheckResultForm
                            uuid={uuid}
                            inc={+inc}
                            closeModal={() => setOpenModal(false)}
                        />
                    </Modal>
                )}
        </Page>
    )
}
