import { IMerchant } from '../../../types/data'
import { API } from 'aws-amplify'
import { API_NAME } from '../../../config/aws'

export const get = async (id: string): Promise<IMerchant> => {
    const { data } = await API.get(API_NAME, `/merchants/${id}`, {})
    return data
}

export const getAll = async (): Promise<IMerchant[]> => {
    const { data } = await API.get(API_NAME, '/merchants', {})
    return data
}

export const createMerchant = async (body: IMerchant): Promise<IMerchant> => {
    const { data } = await API.post(API_NAME, '/merchants', { body })
    return data
}

export const updateMerchant = async (body: IMerchant): Promise<IMerchant> => {
    const { data } = await API.put(API_NAME, '/merchants', { body })
    return data
}

export const deleteMerchant = async (id: string): Promise<void> => {
    return await API.del(API_NAME, `/merchants/${id}`, {})
}
