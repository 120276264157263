import styles from '../index.module.scss'
import { useForm } from 'react-hook-form'
import { FixType, IDictionaryIpAddress } from '../../../types/data'
import { IpType, Risk } from '../../../config/enums'
import { Button } from '../../button'
import { useMutation, useQueryClient } from 'react-query'
import * as dictionaryIpAddressApi from '../../../app/services/api/dictionaryIpAddressApi'
import { toast } from 'react-toastify'

interface TypeProps {
    closeModal(): void
}

export const DictionaryIpAddressCreateForm = ({ closeModal }: TypeProps) => {
    const dictionaryIpAddress: IDictionaryIpAddress = {
        risk: Risk.Sanction,
        ipType: IpType.Ipv4,
        ipAddr: '',
        ipNum: 0,
    }

    const queryClient = useQueryClient()
    const { mutate: createDictionaryIpAddress, isLoading } = useMutation(
        (dictionaryIpAddress: IDictionaryIpAddress) =>
            dictionaryIpAddressApi.createDictionaryIpAddress(dictionaryIpAddress),
        {
            onSuccess: () => {
                toast.success('Dictionary IP-address was successfully created!')
                queryClient.invalidateQueries('dictionary-ip-addresses')
                closeModal()
            },
            onError: (error: Error) => {
                toast.error(`Something went wrong: ${error.message}`)
            },
        },
    )
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({
        mode: 'onBlur',
        defaultValues: dictionaryIpAddress,
    })

    const onSubmit = (data: FixType) => createDictionaryIpAddress(data)

    return (
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <label className={`${styles.inputWrapper} ${styles.fullWidth}`}>
                <span className={styles.inputLabel}>Risk</span>
                <select
                    className={styles.inputControl}
                    aria-invalid={errors.risk ? 'true' : 'false'}
                    {...register('risk', { required: 'Risk is required!' })}
                >
                    {Object.values(Risk).map((item) => (
                        <option key={item} value={item}>
                            {item}
                        </option>
                    ))}
                </select>
                {errors.risk && (
                    <p role='alert' className={styles.inputError}>
                        {errors.risk?.message}
                    </p>
                )}
            </label>

            <label className={`${styles.inputWrapper} ${styles.fullWidth}`}>
                <span className={styles.inputLabel}>Type</span>
                <select
                    className={styles.inputControl}
                    aria-invalid={errors.ipType ? 'true' : 'false'}
                    {...register('ipType', { required: 'Type is required!' })}
                >
                    {Object.values(IpType).map((item) => (
                        <option key={item} value={item}>
                            {item}
                        </option>
                    ))}
                </select>
                {errors.ipType && (
                    <p role='alert' className={styles.inputError}>
                        {errors.ipType?.message}
                    </p>
                )}
            </label>

            <label className={`${styles.inputWrapper} ${styles.fullWidth}`}>
                <span className={styles.inputLabel}>Address</span>
                <input
                    type={'text'}
                    {...register('ipAddr', {
                        required: 'Address is required!',
                    })}
                    className={styles.inputControl}
                    aria-invalid={errors.ipAddr ? 'true' : 'false'}
                />
                {errors.ipAddr && (
                    <p role='alert' className={styles.inputError}>
                        {errors.ipAddr?.message}
                    </p>
                )}
            </label>

            <label className={`${styles.inputWrapper} ${styles.fullWidth}`}>
                <span className={styles.inputLabel}>Num</span>
                <input
                    type={'number'}
                    {...register('ipNum', {
                        valueAsNumber: true,
                    })}
                    className={styles.inputControl}
                    aria-invalid={errors.ipNum ? 'true' : 'false'}
                />
                {errors.ipNum && (
                    <p role='alert' className={styles.inputError}>
                        {errors.ipNum?.message}
                    </p>
                )}
            </label>

            <div className={styles.action}>
                <Button type={'submit'} disabled={isLoading}>
                    Save
                </Button>
            </div>
        </form>
    )
}
