import * as React from 'react'
import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    Row,
    Table as TanstackTable,
    SortingState,
    useReactTable,
} from '@tanstack/react-table'
import styles from './index.module.scss'
import { CSSProperties, useState } from 'react'
import { FixType } from '../../types/data'

type CustomTable<TData> = TanstackTable<TData> & {
    options?: {
        meta?: {
            getRowStyles?: (row: Row<TData>) => CSSProperties
        }
    }
}

type TypeProps = {
    columns: FixType[]
    data: FixType[]
    globalFilter: string
    sortBy?: [
        {
            id: string
            desc: boolean
        },
    ]
    getRowStyles?: (row: Row<FixType>) => CSSProperties
    onRowClick?: (data: FixType) => void
}

export const Table = ({
    columns,
    data,
    globalFilter,
    sortBy,
    getRowStyles,
    onRowClick,
}: TypeProps) => {
    const [sorting, setSorting] = useState<SortingState>(sortBy ?? [])
    const table = useReactTable({
        columns,
        data,
        state: {
            globalFilter,
            sorting,
        },
        meta: {
            getRowStyles,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
    }) as CustomTable<FixType>

    return (
        <table className={styles.table}>
            <thead className={styles.thead}>
                {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => {
                            return (
                                <th key={header.id} colSpan={header.colSpan} className={styles.th}>
                                    {header.isPlaceholder ? null : (
                                        <div
                                            {...{
                                                className: header.column.getCanSort()
                                                    ? 'cursor-pointer select-none'
                                                    : '',
                                                onClick: header.column.getToggleSortingHandler(),
                                            }}
                                        >
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext(),
                                            )}
                                            {{
                                                asc: ' 🔼',
                                                desc: ' 🔽',
                                            }[header.column.getIsSorted() as string] ?? null}
                                        </div>
                                    )}
                                </th>
                            )
                        })}
                    </tr>
                ))}
            </thead>
            <tbody>
                {table.getRowModel().rows.map((row) => (
                    <tr
                        key={row.id}
                        style={
                            getRowStyles && table.options.meta?.getRowStyles
                                ? table.options.meta.getRowStyles(row)
                                : undefined
                        }
                        className={`${onRowClick ? styles.clickable : ''}`}
                        onClick={() => (onRowClick ? onRowClick(row.original) : null)}
                    >
                        {row.getVisibleCells().map((cell) => (
                            <td key={cell.id} className={styles.td}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    )
}
