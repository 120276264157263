import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

export const LoginPage = () => {
    const { route } = useAuthenticator((context) => [context.route])
    const location = useLocation()
    const navigate = useNavigate()
    const from = location.state?.from?.pathname || '/'

    useEffect(() => {
        if (route === 'authenticated') {
            navigate(from, { replace: true })
        }
    }, [route, navigate, from])

    return <Authenticator variation={'modal'} hideSignUp={true} />
}
