import { API } from 'aws-amplify'
import { API_NAME } from '../../../config/aws'
import { IDictionaryPayer } from '../../../types/data'

export const get = async (uuid: string): Promise<IDictionaryPayer> => {
    const { data } = await API.get(API_NAME, `/dictionary/payers/${uuid}`, {})
    return data
}

export const getAll = async (): Promise<IDictionaryPayer[]> => {
    const { data } = await API.get(API_NAME, '/dictionary/payers', {})
    return data
}

export const createDictionaryPayer = async (body: IDictionaryPayer): Promise<IDictionaryPayer> => {
    const { data } = await API.post(API_NAME, '/dictionary/payers', { body })
    return data
}

export const updateDictionaryPayer = async (body: IDictionaryPayer): Promise<IDictionaryPayer> => {
    const { data } = await API.put(API_NAME, '/dictionary/payers', { body })
    return data
}

export const deleteDictionaryPayer = async (uuid: string): Promise<void> => {
    return await API.del(API_NAME, `/dictionary/payers/${uuid}`, {})
}
