import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { LoginPage } from './pages/login'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { getAllowedRoutes } from './utils'
import React from 'react'
import { RequireAuth } from './app/services/requireAuth'
import { IPrivateRoute } from './types/data'
import { Layout } from './components/layout'
import PrivateRoutes from './config/privateRoutesConfig'

function App() {
    const { route, user } = useAuthenticator((context) => [context.route, context.user])

    const groups = user?.getSignInUserSession()?.getAccessToken()?.payload['cognito:groups']

    let allowedRoutes: IPrivateRoute[] = []
    if (route === 'authenticated' && groups) {
        allowedRoutes = getAllowedRoutes(PrivateRoutes, groups)
    }

    return (
        <BrowserRouter>
            <Routes>
                {allowedRoutes.map(
                    ({ path, component: Component, children, ...rest }: IPrivateRoute) => {
                        if (Component) {
                            return (
                                <Route
                                    key={path}
                                    path={path}
                                    {...rest}
                                    element={
                                        <RequireAuth>
                                            <Component>{children}</Component>
                                        </RequireAuth>
                                    }
                                />
                            )
                        }
                    },
                )}
                <Route path={'/login'} element={<LoginPage />} />
                <Route
                    path={'*'}
                    element={
                        <RequireAuth>
                            <Layout />
                        </RequireAuth>
                    }
                />
            </Routes>
        </BrowserRouter>
    )
}

export default App
