import { API } from 'aws-amplify'
import { API_NAME } from '../../../config/aws'
import { IDictionaryIpAddress } from '../../../types/data'

export const get = async (id: number): Promise<IDictionaryIpAddress> => {
    const { data } = await API.get(API_NAME, `/dictionary/ip/${id}`, {})
    return data
}

export const getAll = async (): Promise<IDictionaryIpAddress[]> => {
    const { data } = await API.get(API_NAME, '/dictionary/ip', {})
    return data
}

export const createDictionaryIpAddress = async (
    body: IDictionaryIpAddress,
): Promise<IDictionaryIpAddress> => {
    const { data } = await API.post(API_NAME, '/dictionary/ip', { body })
    return data
}

export const updateDictionaryIpAddress = async (
    body: IDictionaryIpAddress,
): Promise<IDictionaryIpAddress> => {
    const { data } = await API.put(API_NAME, '/dictionary/ip', { body })
    return data
}

export const deleteDictionaryIpAddress = async (id: number): Promise<void> => {
    return await API.del(API_NAME, `/dictionary/ip/${id}`, {})
}
