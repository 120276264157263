import Roles from './roles'
import { TransactionsPage } from '../pages/transactions'
import { MerchantsPage } from '../pages/merchants'
import { IPrivateRoute } from '../types/data'
import { DictionaryCountriesPage } from '../pages/dictionary-countries'
import { RulesPage } from '../pages/rules'
import { RuleExcelPage } from '../pages/rule-excel'
import { DictionaryIpAddressesPage } from '../pages/dictionary-ip-adresses'
import { TransactionPage } from '../pages/transaction'
import { DictionaryPepPage } from '../pages/dictionary-pep'
import { DictionaryKeywordsPage } from '../pages/dictionary-keywords'
import { DictionaryPayersPage } from '../pages/dictionary-payers'
import { DictionaryMerchantsPage } from '../pages/dictionary-merchants'

const PrivateRoutes: IPrivateRoute[] = [
    {
        component: TransactionsPage,
        path: '/transactions',
        title: 'Transactions',
        permission: [Roles.coordinator],
    },
    {
        component: TransactionPage,
        path: '/transactions/:uuid/:inc',
        permission: [Roles.coordinator],
    },
    {
        component: MerchantsPage,
        path: '/merchants',
        title: 'Merchants',
        permission: [Roles.coordinator],
    },
    {
        title: 'Dictionaries',
        permission: [Roles.admin],
        submenu: [
            {
                component: DictionaryCountriesPage,
                path: '/dictionary-countries',
                title: 'Dictionary countries',
                permission: [Roles.admin],
            },
            {
                component: DictionaryIpAddressesPage,
                path: '/dictionary-ip',
                title: 'Dictionary IP-addresses',
                permission: [Roles.admin],
            },
            {
                component: DictionaryPepPage,
                path: '/dictionary-pep',
                title: 'Dictionary peps',
                permission: [Roles.admin],
            },
            {
                component: DictionaryKeywordsPage,
                path: '/dictionary-keywords',
                title: 'Dictionary keywords',
                permission: [Roles.admin],
            },
            {
                component: DictionaryPayersPage,
                path: '/dictionary-payers',
                title: 'Dictionary payers',
                permission: [Roles.admin],
            },
            {
                component: DictionaryMerchantsPage,
                path: '/dictionary-merchants',
                title: 'Dictionary merchants',
                permission: [Roles.admin],
            },
        ],
    },
    {
        component: RulesPage,
        path: '/rules',
        title: 'Rules',
        permission: [Roles.admin],
    },
    {
        component: RuleExcelPage,
        path: '/:category/rules/:id',
        permission: [Roles.admin],
    },
]

export default PrivateRoutes
