import { Page } from '../../components/page'
import styles from '../../modules/table-pages/index.module.scss'
import { Search } from '../../components/search'
import { Table } from '../../components/table'
import { Button } from '../../components/button'
import { Modal } from '../../components/modal'
import { useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import * as dictionaryIpAddressApi from '../../app/services/api/dictionaryIpAddressApi'
import { createColumnHelper } from '@tanstack/react-table'
import { toast } from 'react-toastify'
import { IDictionaryIpAddress } from '../../types/data'
import { DictionaryIpAddressDetail } from '../../components/forms/dictionaryIpAddress/edit-form'
import { DictionaryIpAddressCreateForm } from '../../components/forms/dictionaryIpAddress/create-form'

export const DictionaryIpAddressesPage = () => {
    const queryClient = useQueryClient()
    const { isLoading, data: dictionaryIpAddresses } = useQuery(
        'dictionary-ip-addresses',
        dictionaryIpAddressApi.getAll,
        {
            onError: (error: Error) => {
                toast.error(`Something went wrong: ${error.message}`)
            },
        },
    )
    const { mutate: deleteDictionaryCountry } = useMutation(
        (id: number) => dictionaryIpAddressApi.deleteDictionaryIpAddress(id),
        {
            onSuccess: () => {
                toast.success('Dictionary country was successfully deleted!')
                queryClient.invalidateQueries(['dictionary-ip-addresses'])
            },
            onError: (error: Error) => {
                toast.error(`Something went wrong: ${error.message}`)
            },
        },
    )

    const [tableData, setTableData] = useState<IDictionaryIpAddress[] | undefined>([])
    const [searchFilter, setSearchFilter] = useState<string>('')
    const [activeId, setActiveId] = useState<number>()
    const [openModal, setOpenModal] = useState<boolean>(false)

    const columnHelper = createColumnHelper<IDictionaryIpAddress>()
    const columns = useMemo(
        () => [
            columnHelper.accessor('id', {
                header: 'ID',
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor('risk', {
                header: 'Risk',
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor('ipType', {
                header: 'Type',
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor('ipAddr', {
                header: 'Address',
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor('ipNum', {
                header: 'Num',
                cell: (info) => info.getValue(),
            }),
            columnHelper.display({
                id: 'actions',
                header: '',
                cell: (info) => (
                    <div className={`${styles.dFlex} ${styles.g1}`}>
                        <Button
                            type={'button'}
                            action={'edit'}
                            onClickHandler={() =>
                                info.row.original.id ? onClickEdit(+info.row.original.id) : null
                            }
                        ></Button>
                        <Button
                            type={'button'}
                            action={'delete'}
                            onClickHandler={() =>
                                info.row.original.id ? onClickDelete(+info.row.original.id) : null
                            }
                        ></Button>
                    </div>
                ),
            }),
        ],
        [tableData],
    )

    const onClickEdit = (id: number) => {
        setOpenModal(true)
        setActiveId(id)
    }

    const onClickDelete = (id: number) => {
        if (confirm(`Are you sure to delete record with id ${id}?`)) deleteDictionaryCountry(id)
    }

    const closeModal = () => {
        setOpenModal(false)
        setActiveId(undefined)
    }

    useEffect(() => {
        setTableData(dictionaryIpAddresses)
    }, [dictionaryIpAddresses])

    if (isLoading) {
        return (
            <Page>
                <div>Loading...</div>
            </Page>
        )
    }

    return (
        <Page>
            <div className={styles.wrapper}>
                <div className={`${styles.dFlex} ${styles.mb25}`}>
                    <Search
                        value={searchFilter}
                        onChange={(value: string) => setSearchFilter(value)}
                    />
                </div>
                <div className={styles.mb3}>
                    {tableData && (
                        <Table columns={columns} data={tableData} globalFilter={searchFilter} />
                    )}
                </div>
                <div className={styles.flexEnd}>
                    <Button
                        type={'button'}
                        action={'create'}
                        onClickHandler={() => setOpenModal(true)}
                    >
                        New
                    </Button>
                </div>
            </div>

            <Modal header={'Dictionary IP-address'} isOpen={openModal} closeModal={closeModal}>
                {activeId ? (
                    <DictionaryIpAddressDetail id={activeId} closeModal={closeModal} />
                ) : (
                    <DictionaryIpAddressCreateForm closeModal={closeModal} />
                )}
            </Modal>
        </Page>
    )
}
