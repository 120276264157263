import { IDictionaryCountry } from '../../../types/data'
import { API } from 'aws-amplify'
import { API_NAME } from '../../../config/aws'

export const get = async (id: number): Promise<IDictionaryCountry> => {
    const { data } = await API.get(API_NAME, `/dictionary/countries/${id}`, {})
    return data
}

export const getAll = async (): Promise<IDictionaryCountry[]> => {
    const { data } = await API.get(API_NAME, '/dictionary/countries', {})
    return data
}

export const createDictionaryCountry = async (
    body: IDictionaryCountry,
): Promise<IDictionaryCountry> => {
    const { data } = await API.post(API_NAME, '/dictionary/countries', { body })
    return data
}

export const updateDictionaryCountry = async (
    body: IDictionaryCountry,
): Promise<IDictionaryCountry> => {
    const { data } = await API.put(API_NAME, '/dictionary/countries', { body })
    return data
}

export const deleteDictionaryCountry = async (id: number): Promise<void> => {
    return await API.del(API_NAME, `/dictionary/countries/${id}`, {})
}
