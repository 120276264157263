import { API } from 'aws-amplify'
import { API_NAME } from '../../../config/aws'
import { IRule, IRuleData, IRuleFile, IRuleStatus } from '../../../types/data'
import { RuleCategory } from '../../../config/enums'

export const get = async (category: RuleCategory, id: string): Promise<IRuleFile> => {
    const { data } = await API.get(API_NAME, `/${category}/rules/${id}`, {})
    return data
}

export const getAll = async (category: RuleCategory): Promise<IRule[]> => {
    const { data } = await API.get(API_NAME, `/${category}/rules`, {})
    return data
}

export const createRule = async (category: RuleCategory, body: IRuleData): Promise<IRule> => {
    const { data } = await API.post(API_NAME, `/${category}/rules`, { body })
    return data
}

export const updateRuleStatus = async (
    category: RuleCategory,
    id: string,
    body: IRuleStatus,
): Promise<IRuleStatus> => {
    const { data } = await API.patch(API_NAME, `/${category}/rules/${id}`, { body })
    return data
}

export const deleteRule = async (category: RuleCategory, id: string): Promise<void> => {
    return await API.del(API_NAME, `/${category}/rules/${id}`, {})
}
