import styles from './index.module.scss'

import { ReactNode } from 'react'
import { Header } from '../header'
import { Footer } from '../footer'

type Props = {
    children?: ReactNode
}

export const Layout = ({ children }: Props) => {
    return (
        <div className={styles.main}>
            <Header />
            <main className={styles.content}>{children ?? null}</main>
            <Footer />
        </div>
    )
}
