import React from 'react'
import { Layout } from '../layout'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

type Props = {
    children: React.ReactNode
}

export const Page = ({ children }: Props) => {
    return (
        <Layout>
            <div className='container'>{children}</div>
            <ToastContainer position={'bottom-right'} />
        </Layout>
    )
}
