import { default as configApi } from '../config/api'
import { Auth } from 'aws-amplify'
import { env } from '../env';

export const API_NAME = 'api'
export const API_RULE_NAME = 'api_rule'

const API_ENDPOINT = `${configApi.host}${configApi.port ? `:${configApi.port}` : ''}${
    configApi.path ? `${configApi.path}` : ''
}`

const API_RULE_ENDPOINT = `${configApi.host}${configApi.port ? `:${configApi.port}` : ''}${
    env.REACT_APP_API_RULE_PATH ?? ''
}`

export const awsConfig = {
    Auth: {
        region: env.REACT_APP_AWS_AUTH_REGION,
        userPoolId: env.REACT_APP_AWS_AUTH_USER_POOL_ID,
        userPoolWebClientId: env.REACT_APP_AWS_AUTH_USER_POOL_WEB_CLIENT_ID,
    },
    API: {
        endpoints: [
            {
                name: API_NAME,
                endpoint: API_ENDPOINT,
                custom_header: async () => ({
                    Authorization: `Bearer ${(await Auth.currentSession())
                        .getAccessToken()
                        .getJwtToken()}`,
                }),
            },
            {
                name: API_RULE_NAME,
                endpoint: API_RULE_ENDPOINT,
                custom_header: async () => ({
                    Authorization: `Bearer ${(await Auth.currentSession())
                        .getAccessToken()
                        .getJwtToken()}`,
                }),
            },
        ],
    },
}
