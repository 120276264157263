import styles from '../index.module.scss'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useForm } from 'react-hook-form'
import { FixType, IDictionaryPep } from '../../../types/data'
import * as dictionaryPepApi from '../../../app/services/api/dictionaryPepApi'
import { Button } from '../../button'
import { toast } from 'react-toastify'

export const DictionaryPepDetail = ({ uuid, closeModal }: { uuid: string; closeModal(): void }) => {
    const queryClient = useQueryClient()
    const { data } = useQuery(['dictionary-pep', uuid], () => dictionaryPepApi.get(uuid))
    const { mutate: updateDictionaryPep, isLoading } = useMutation(
        (dictionaryCountry: IDictionaryPep) =>
            dictionaryPepApi.updateDictionaryPep(dictionaryCountry),
        {
            onSuccess: () => {
                toast.success('Dictionary pep was successfully updated!')
                queryClient.invalidateQueries(['dictionary-pep', uuid])
                queryClient.invalidateQueries(['dictionary-peps'])
                closeModal()
            },
            onError: (error: Error) => {
                toast.error(`Something went wrong: ${error.message}`)
            },
        },
    )

    if (data) {
        return (
            <DictionaryPepForm
                dictionaryPep={data}
                isLoading={isLoading}
                onSubmit={updateDictionaryPep}
            />
        )
    }

    return <div>Loading...</div>
}

interface TypeProps {
    dictionaryPep: IDictionaryPep
    isLoading: boolean
    onSubmit: (v: FixType, p: { onSuccess: () => void }) => void
}

const DictionaryPepForm = ({ dictionaryPep, isLoading, onSubmit }: TypeProps) => {
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm<IDictionaryPep>({
        defaultValues: dictionaryPep,
        mode: 'onBlur',
    })

    return (
        <form
            className={styles.form}
            onSubmit={handleSubmit((values: IDictionaryPep) =>
                onSubmit(values, { onSuccess: () => reset() }),
            )}
        >
            <label className={`${styles.inputWrapper} ${styles.fullWidth}`}>
                <span className={styles.inputLabel}>ID</span>
                <input
                    type={'text'}
                    disabled={true}
                    className={styles.inputControl}
                    aria-invalid={errors.uuid ? 'true' : 'false'}
                    {...register('uuid', { required: 'ID is required!' })}
                />
            </label>

            <label className={`${styles.inputWrapper} ${styles.fullWidth}`}>
                <span className={styles.inputLabel}>Data</span>

                <textarea
                    cols={30}
                    rows={10}
                    {...register('data', {
                        required: 'Data is required!',
                    })}
                    className={styles.inputControl}
                    aria-invalid={errors.data ? 'true' : 'false'}
                ></textarea>

                {errors.data && (
                    <p role='alert' className={styles.inputError}>
                        {errors.data?.message}
                    </p>
                )}
            </label>

            <div className={styles.action}>
                <Button type={'submit'} disabled={isLoading}>
                    Save
                </Button>
            </div>
        </form>
    )
}
