declare global {
    interface Window {
        env: any
    }
}

type EnvType = {
    REACT_APP_API_HOST: string,
    REACT_APP_API_PORT: string,
    REACT_APP_API_PATH: string,
    REACT_APP_API_RULE_PATH: string
    REACT_APP_AWS_AUTH_REGION: string
    REACT_APP_AWS_AUTH_USER_POOL_ID: string
    REACT_APP_AWS_AUTH_USER_POOL_WEB_CLIENT_ID: string
}
export const env: EnvType = { ...process.env, ...window.env }