import styles from '../index.module.scss'
import { useForm } from 'react-hook-form'
import { IDictionaryPayer } from '../../../types/data'
import { Button } from '../../button'
import { useMutation, useQueryClient } from 'react-query'
import * as dictionaryPayerApi from '../../../app/services/api/dictionaryPayerApi'
import { toast } from 'react-toastify'
import { Risk } from '../../../config/enums'

interface TypeProps {
    closeModal(): void
}

export const DictionaryPayerCreateForm = ({ closeModal }: TypeProps) => {
    const dictionaryPayer: IDictionaryPayer = {
        uuid: '',
        risk: Risk.Sanction,
        note: '',
        data: null,
    }

    const queryClient = useQueryClient()
    const { mutate: createDictionaryPayer, isLoading } = useMutation(
        (dictionaryPayer: IDictionaryPayer) =>
            dictionaryPayerApi.createDictionaryPayer(dictionaryPayer),
        {
            onSuccess: () => {
                toast.success('Dictionary payer was successfully created!')
                queryClient.invalidateQueries('dictionary-payers')
                closeModal()
            },
            onError: (error: Error) => {
                toast.error(`Something went wrong: ${error.message}`)
            },
        },
    )
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({
        mode: 'onBlur',
        defaultValues: dictionaryPayer,
    })

    const onSubmit = (data: IDictionaryPayer) => createDictionaryPayer(data)

    return (
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <label className={`${styles.inputWrapper} ${styles.fullWidth}`}>
                <span className={styles.inputLabel}>UUID</span>
                <input
                    type={'string'}
                    className={styles.inputControl}
                    aria-invalid={errors.uuid ? 'true' : 'false'}
                    {...register('uuid', { required: 'UUID is required!' })}
                />
            </label>

            <label className={`${styles.inputWrapper} ${styles.fullWidth}`}>
                <span className={styles.inputLabel}>Risk</span>
                <select
                    required
                    className={styles.inputControl}
                    aria-invalid={errors.risk ? 'true' : 'false'}
                    {...register('risk', { required: 'Risk is required!' })}
                >
                    {Object.values(Risk).map((item) => (
                        <option key={item} value={item}>
                            {item}
                        </option>
                    ))}
                </select>
                {errors.risk && (
                    <p role='alert' className={styles.inputError}>
                        {errors.risk?.message}
                    </p>
                )}
            </label>

            <label className={`${styles.inputWrapper} ${styles.fullWidth}`}>
                <span className={styles.inputLabel}>Note</span>
                <textarea
                    cols={30}
                    rows={10}
                    {...register('note')}
                    className={styles.inputControl}
                ></textarea>
            </label>

            <div className={styles.action}>
                <Button type={'submit'} disabled={isLoading}>
                    Save
                </Button>
            </div>
        </form>
    )
}
