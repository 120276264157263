import styles from '../index.module.scss'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import * as merchantApi from '../../../app/services/api/merchantApi'
import { useForm } from 'react-hook-form'
import { FixType, IBankAccount, IContact, IMerchant } from '../../../types/data'
import { Button } from '../../button'
import { toast } from 'react-toastify'

export const MerchantDetail = ({ id, closeModal }: { id: string; closeModal(): void }) => {
    const queryClient = useQueryClient()
    const { data } = useQuery({
        queryKey: ['merchant', id],
        queryFn: () => merchantApi.get(id),
    })
    const { mutate, isLoading } = useMutation(
        (merchant: IMerchant) => merchantApi.updateMerchant(merchant),
        {
            onSuccess: () => {
                toast.success('Merchant was successfully updated!')
                queryClient.invalidateQueries(['merchant', id])
                queryClient.invalidateQueries(['merchants'])
                closeModal()
            },
            onError: (error: Error) => {
                toast.error(`Something went wrong: ${error.message}`)
            },
        },
    )

    if (data) {
        return <MerchantForm merchant={data} isLoading={isLoading} onSubmit={mutate} />
    }

    return <div>Loading...</div>
}

interface TypeProps {
    merchant: IMerchant
    isLoading: boolean
    onSubmit: (v: FixType, p: { onSuccess: () => void }) => void
}

const MerchantForm = ({ merchant, isLoading, onSubmit }: TypeProps) => {
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm<IMerchant>({
        defaultValues: merchant,
        mode: 'onBlur',
    })

    return (
        <form
            className={styles.form}
            onSubmit={handleSubmit((values: IMerchant) =>
                onSubmit(values, { onSuccess: () => reset() }),
            )}
        >
            <label className={`${styles.inputWrapper} ${styles.fullWidth}`}>
                <span className={styles.inputLabel}>Total Monthly Amount from KYC</span>
                <input
                    type={'number'}
                    {...register('totalAmount1mKYC', {
                        required: 'Total Amount 1mKYC is required!',
                        valueAsNumber: true,
                    })}
                    className={styles.inputControl}
                    aria-invalid={errors.totalAmount1mKYC ? 'true' : 'false'}
                />
                {errors.totalAmount1mKYC && (
                    <p role='alert' className={styles.inputError}>
                        {errors.totalAmount1mKYC?.message}
                    </p>
                )}
            </label>

            <label className={`${styles.inputWrapper} ${styles.fullWidth}`}>
                <span className={styles.inputLabel}>ID</span>
                <input
                    type={'text'}
                    disabled={true}
                    {...register('uuid')}
                    className={styles.inputControl}
                />
            </label>

            <label className={`${styles.inputWrapper} ${styles.fullWidth}`}>
                <span className={styles.inputLabel}>Business Name</span>
                <input
                    type={'text'}
                    disabled={true}
                    {...register('businessName', { required: true })}
                    className={styles.inputControl}
                    aria-invalid={errors.businessName ? 'true' : 'false'}
                />
            </label>

            <label className={`${styles.inputWrapper} ${styles.fullWidth}`}>
                <span className={styles.inputLabel}>Public Business Name</span>
                <input
                    type={'text'}
                    disabled={true}
                    {...register('publicBusinessName', { required: true })}
                    className={styles.inputControl}
                />
            </label>

            <label className={`${styles.inputWrapper} ${styles.fullWidth}`}>
                <span className={styles.inputLabel}>Url</span>
                <input
                    type={'text'}
                    disabled={true}
                    {...register('url', {
                        pattern:
                            /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/,
                    })}
                    className={styles.inputControl}
                />
            </label>

            <label className={styles.inputWrapper}>
                <span className={styles.inputLabel}>Phone Number</span>
                <input
                    type={'tel'}
                    disabled={true}
                    {...register('phoneNumber')}
                    className={styles.inputControl}
                />
            </label>

            <label className={styles.inputWrapper}>
                <span className={styles.inputLabel}>Email</span>
                <input
                    type={'email'}
                    disabled={true}
                    {...register('email', {
                        pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                    })}
                    className={styles.inputControl}
                />
            </label>

            <label className={styles.inputWrapper}>
                <span className={styles.inputLabel}>Business Identifier</span>
                <input
                    type={'text'}
                    disabled={true}
                    {...register('businessIdentifier', { required: true })}
                    className={styles.inputControl}
                />
            </label>

            <label className={styles.inputWrapper}>
                <span className={styles.inputLabel}>Country</span>
                <input
                    type={'text'}
                    disabled={true}
                    {...register('address.country', { required: true })}
                    className={styles.inputControl}
                />
            </label>

            <label className={styles.inputWrapper}>
                <span className={styles.inputLabel}>City</span>
                <input
                    type={'text'}
                    disabled={true}
                    {...register('address.city', { required: true })}
                    className={styles.inputControl}
                />
            </label>

            <label className={styles.inputWrapper}>
                <span className={styles.inputLabel}>Zip</span>
                <input
                    type={'text'}
                    disabled={true}
                    {...register('address.zip', { required: true })}
                    className={styles.inputControl}
                />
            </label>

            <label className={styles.inputWrapper}>
                <span className={styles.inputLabel}>Line 1</span>
                <input
                    type={'text'}
                    disabled={true}
                    {...register('address.line1', { required: true })}
                    className={styles.inputControl}
                />
            </label>

            <label className={styles.inputWrapper}>
                <span className={styles.inputLabel}>Line 2</span>
                <input
                    type={'text'}
                    disabled={true}
                    {...register('address.line2')}
                    className={styles.inputControl}
                />
            </label>

            {merchant.contacts.length && (
                <fieldset className={`${styles.fieldset} ${styles.fullWidth}`}>
                    <legend>Contacts</legend>
                    {merchant.contacts.map((contact: IContact, index) => (
                        <fieldset key={contact.email} className={styles.fieldset}>
                            <legend>Contact {index + 1}</legend>
                            <label className={styles.inputWrapper}>
                                <span className={styles.inputLabel}>Name</span>
                                <input
                                    type={'text'}
                                    disabled={true}
                                    value={contact.name}
                                    className={styles.inputControl}
                                />
                            </label>

                            <label className={styles.inputWrapper}>
                                <span className={styles.inputLabel}>Role</span>
                                <input
                                    type={'text'}
                                    disabled={true}
                                    value={contact.role}
                                    className={styles.inputControl}
                                />
                            </label>

                            <label className={styles.inputWrapper}>
                                <span className={styles.inputLabel}>Email</span>
                                <input
                                    type={'text'}
                                    disabled={true}
                                    value={contact.email}
                                    className={styles.inputControl}
                                />
                            </label>

                            <label className={styles.inputWrapper}>
                                <span className={styles.inputLabel}>Email</span>
                                <input
                                    type={'tel'}
                                    disabled={true}
                                    value={contact.phoneNumber}
                                    className={styles.inputControl}
                                />
                            </label>
                        </fieldset>
                    ))}
                </fieldset>
            )}

            {merchant.bankAccounts.length && (
                <fieldset className={`${styles.fieldset} ${styles.fullWidth}`}>
                    <legend>Bank Accounts</legend>
                    {merchant.bankAccounts.map((bankAccount: IBankAccount, index) => (
                        <fieldset key={bankAccount.uuid} className={styles.fieldset}>
                            <legend>Bank Account {index + 1}</legend>

                            <label className={styles.inputWrapper}>
                                <span className={styles.inputLabel}>ID</span>
                                <input
                                    type={'text'}
                                    disabled={true}
                                    value={bankAccount.uuid}
                                    className={styles.inputControl}
                                />
                            </label>

                            <label className={styles.inputWrapper}>
                                <span className={styles.inputLabel}>IBAN</span>
                                <input
                                    type={'text'}
                                    disabled={true}
                                    value={bankAccount.iban}
                                    className={styles.inputControl}
                                />
                            </label>

                            <label className={styles.inputWrapper}>
                                <span className={styles.inputLabel}>BIC</span>
                                <input
                                    type={'text'}
                                    disabled={true}
                                    value={bankAccount.bic}
                                    className={styles.inputControl}
                                />
                            </label>

                            <label className={styles.inputWrapper}>
                                <span className={styles.inputLabel}>Owner Name</span>
                                <input
                                    type={'text'}
                                    disabled={true}
                                    value={bankAccount.ownerName}
                                    className={styles.inputControl}
                                />
                            </label>

                            <label className={styles.inputWrapper}>
                                <span className={styles.inputLabel}>Owner Email</span>
                                <input
                                    type={'email'}
                                    disabled={true}
                                    value={bankAccount.ownerEmail}
                                    className={styles.inputControl}
                                />
                            </label>

                            <label className={styles.inputWrapper}>
                                <span className={styles.inputLabel}>Locale</span>
                                <input
                                    type={'text'}
                                    disabled={true}
                                    value={bankAccount.locale}
                                    className={styles.inputControl}
                                />
                            </label>
                        </fieldset>
                    ))}
                </fieldset>
            )}

            <div className={styles.action}>
                <Button type={'submit'} disabled={isLoading}>
                    Save
                </Button>
            </div>
        </form>
    )
}
