import { API } from 'aws-amplify'
import { API_NAME } from '../../../config/aws'
import { IDictionaryKeyword } from '../../../types/data'

export const get = async (id: number): Promise<IDictionaryKeyword> => {
    const { data } = await API.get(API_NAME, `/dictionary/keywords/${id}`, {})
    return data
}

export const getAll = async (): Promise<IDictionaryKeyword[]> => {
    const { data } = await API.get(API_NAME, '/dictionary/keywords', {})
    return data
}

export const createDictionaryKeyword = async (
    body: IDictionaryKeyword,
): Promise<IDictionaryKeyword> => {
    const { data } = await API.post(API_NAME, '/dictionary/keywords', { body })
    return data
}

export const updateDictionaryKeyword = async (
    body: IDictionaryKeyword,
): Promise<IDictionaryKeyword> => {
    const { data } = await API.put(API_NAME, '/dictionary/keywords', { body })
    return data
}

export const deleteDictionaryKeyword = async (id: number): Promise<void> => {
    return await API.del(API_NAME, `/dictionary/keywords/${id}`, {})
}
