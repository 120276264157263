import { useMutation, useQuery, useQueryClient } from 'react-query'
import { CSSProperties, useEffect, useMemo, useState } from 'react'
import { createColumnHelper } from '@tanstack/react-table'
import styles from '../../modules/table-pages/index.module.scss'
import { Button } from '../../components/button'
import { Page } from '../../components/page'
import { Search } from '../../components/search'
import { Table } from '../../components/table'
import { IRule, updateRuleStatusVariables } from '../../types/data'
import * as ruleApi from '../../app/services/api/ruleApi'
import { RuleCategory, RuleStatus } from '../../config/enums'
import { useNavigate } from 'react-router-dom'
import { Modal } from '../../components/modal'
import { RuleCreateForm } from '../../components/forms/rule/create-form'
import { formatDate } from '../../utils'
import { toast } from 'react-toastify'

export const RulesPage = () => {
    const queryClient = useQueryClient()
    const [activeCategory, setActiveCategory] = useState<RuleCategory>(RuleCategory.Merchant)
    const { data: rules, isLoading } = useQuery(
        ['rules', activeCategory],
        () => ruleApi.getAll(activeCategory),
        {
            onError: (error: Error) => {
                toast.error(`Something went wrong: ${error.message}`)
            },
        },
    )
    const { mutate: deleteRule } = useMutation(
        (id: string) => ruleApi.deleteRule(activeCategory, id),
        {
            onSuccess: () => {
                toast.success('Rule was successfully deleted!')
                queryClient.invalidateQueries(['rules'])
            },
            onError: (error: Error) => {
                toast.error(`Something went wrong: ${error.message}`)
            },
        },
    )
    const { mutate: updateRuleStatus } = useMutation(
        ({ id, status }: updateRuleStatusVariables) =>
            ruleApi.updateRuleStatus(activeCategory, id, { status }),
        {
            onSuccess: () => {
                toast.success('Rule status was successfully updated!')
                queryClient.invalidateQueries('rules')
            },
        },
    )

    const [tableData, setTableData] = useState<IRule[] | undefined>([])
    const [searchFilter, setSearchFilter] = useState<string>('')
    const [openModal, setOpenModal] = useState<boolean>(false)

    const navigate = useNavigate()

    const onRuleStatusChange = (id: string, status: RuleStatus) => {
        updateRuleStatus({ id, status })
    }

    const columnHelper = createColumnHelper<IRule>()
    const columns = useMemo(
        () => [
            columnHelper.accessor('category', {
                header: 'Category',
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor('status', {
                header: 'Status',
                cell: (info) => {
                    const activeStatus: RuleStatus = info.getValue()

                    if (activeStatus === 'active') return activeStatus

                    const statuses = Object.values(RuleStatus)
                    const filteredStatuses = statuses.filter((status: RuleStatus) =>
                        activeStatus === 'inactive' ? status !== 'active' : true,
                    )

                    return (
                        filteredStatuses.length && (
                            <select
                                value={info.getValue()}
                                onChange={(evt) =>
                                    onRuleStatusChange(
                                        info.row.original.uuid,
                                        evt.target.value as RuleStatus,
                                    )
                                }
                            >
                                {filteredStatuses.map((status: RuleStatus) => (
                                    <option key={status} value={status}>
                                        {status}
                                    </option>
                                ))}
                            </select>
                        )
                    )
                },
            }),
            columnHelper.accessor('revision', {
                header: 'Revision',
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor('uuid', {
                header: 'ID',
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor('created_at', {
                header: 'Created at',
                cell: (info) => formatDate(new Date(info.getValue())),
            }),
            columnHelper.accessor('updated_at', {
                header: 'Updated at',
                cell: (info) => formatDate(new Date(info.getValue())),
            }),
            columnHelper.display({
                id: 'actions',
                header: '',
                cell: (info) => (
                    <div className={`${styles.dFlex} ${styles.g1}`}>
                        <Button
                            type={'button'}
                            className={styles.excelLink}
                            onClickHandler={() =>
                                navigate(`/${activeCategory}/rules/${info.row.original.uuid}`)
                            }
                        ></Button>
                        {info.row.original.status !== 'active' && (
                            <Button
                                type={'button'}
                                action={'delete'}
                                onClickHandler={() => onClickDelete(info.row.original.uuid)}
                            ></Button>
                        )}
                    </div>
                ),
            }),
        ],
        [tableData],
    )

    const onClickDelete = (id: string) => {
        if (confirm(`Are you sure to delete record with id ${id}?`)) deleteRule(id)
    }

    const closeModal = () => {
        setOpenModal(false)
    }

    const getRowStylesByStatus = (status: RuleStatus): CSSProperties => {
        const backgroundColor = status === 'active' ? '#d1e3e3' : '#f6f4f4'
        return { backgroundColor }
    }

    useEffect(() => {
        setTableData(rules)
    }, [rules])

    if (isLoading) {
        return (
            <Page>
                <div>Loading...</div>
            </Page>
        )
    }

    return (
        <Page>
            <div className={styles.wrapper}>
                <div className={`${styles.dFlex} ${styles.mb3}`}>
                    {Object.values(RuleCategory).map((category) => (
                        <Button
                            className={`${styles.tab} ${
                                activeCategory === category ? styles.tabActive : ''
                            }`}
                            type={'button'}
                            key={category}
                            onClickHandler={() => setActiveCategory(category)}
                        >
                            {category}
                        </Button>
                    ))}
                </div>

                <div className={`${styles.dFlex} ${styles.mb25}`}>
                    <Search
                        value={searchFilter}
                        onChange={(value: string) => setSearchFilter(value)}
                    />
                </div>
                <div className={styles.mb3}>
                    {tableData && (
                        <Table
                            columns={columns}
                            data={tableData}
                            globalFilter={searchFilter}
                            sortBy={[{ id: 'updated_at', desc: true }]}
                            getRowStyles={(row) => getRowStylesByStatus(row.original.status)}
                        />
                    )}
                </div>
                <div className={styles.flexEnd}>
                    <Button
                        type={'button'}
                        action={'create'}
                        onClickHandler={() => setOpenModal(true)}
                    >
                        New
                    </Button>
                </div>

                <Modal header={'Rule'} isOpen={openModal} closeModal={closeModal}>
                    <RuleCreateForm category={activeCategory} closeModal={closeModal} />
                </Modal>
            </div>
        </Page>
    )
}
