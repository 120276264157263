import { Page } from '../../components/page'
import styles from '../../modules/table-pages/index.module.scss'
import { Search } from '../../components/search'
import { Table } from '../../components/table'
import { Button } from '../../components/button'
import { Modal } from '../../components/modal'
import { useEffect, useMemo, useState } from 'react'
import { IDictionaryCountry } from '../../types/data'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import * as dictionaryCountryApi from '../../app/services/api/dictionaryCountryApi'
import { createColumnHelper } from '@tanstack/react-table'
import countries from 'i18n-iso-countries'
import enLocale from 'i18n-iso-countries/langs/en.json'
import { DictionaryCountryDetail } from '../../components/forms/dictionaryCountry/edit-form'
import { DictionaryCountryCreateForm } from '../../components/forms/dictionaryCountry/create-form'
import { toast } from 'react-toastify'

countries.registerLocale(enLocale)

export const DictionaryCountriesPage = () => {
    const queryClient = useQueryClient()
    const { isLoading, data: dictionaryCountries } = useQuery(
        'dictionary-countries',
        dictionaryCountryApi.getAll,
        {
            onError: (error: Error) => {
                toast.error(`Something went wrong: ${error.message}`)
            },
        },
    )
    const { mutate: deleteDictionaryCountry } = useMutation(
        (id: number) => dictionaryCountryApi.deleteDictionaryCountry(id),
        {
            onSuccess: () => {
                toast.success('Dictionary country was successfully deleted!')
                queryClient.invalidateQueries(['dictionary-countries'])
            },
            onError: (error: Error) => {
                toast.error(`Something went wrong: ${error.message}`)
            },
        },
    )

    const [tableData, setTableData] = useState<IDictionaryCountry[] | undefined>([])
    const [searchFilter, setSearchFilter] = useState<string>('')
    const [activeId, setActiveId] = useState<number>()
    const [openModal, setOpenModal] = useState<boolean>(false)

    const columnHelper = createColumnHelper<IDictionaryCountry>()
    const columns = useMemo(
        () => [
            columnHelper.accessor('id', {
                header: 'ID',
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor('risk', {
                header: 'Risk',
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor('countryCode', {
                header: 'Country (country code)',
                cell: (info) => countries.getName(info.getValue().toUpperCase(), 'en'),
            }),
            columnHelper.display({
                id: 'actions',
                header: '',
                cell: (info) => (
                    <div className={`${styles.dFlex} ${styles.g1}`}>
                        <Button
                            type={'button'}
                            action={'edit'}
                            onClickHandler={() =>
                                info.row.original.id ? onClickEdit(info.row.original.id) : null
                            }
                        ></Button>
                        <Button
                            type={'button'}
                            action={'delete'}
                            onClickHandler={() =>
                                info.row.original.id ? onClickDelete(info.row.original.id) : null
                            }
                        ></Button>
                    </div>
                ),
            }),
        ],
        [tableData],
    )

    const onClickEdit = (id: number) => {
        setOpenModal(true)
        setActiveId(id)
    }

    const onClickDelete = (id: number) => {
        if (confirm(`Are you sure to delete record with id ${id}?`)) deleteDictionaryCountry(id)
    }

    const closeModal = () => {
        setOpenModal(false)
        setActiveId(undefined)
    }

    useEffect(() => {
        setTableData(dictionaryCountries)
    }, [dictionaryCountries])

    if (isLoading) {
        return (
            <Page>
                <div>Loading...</div>
            </Page>
        )
    }

    return (
        <Page>
            <div className={styles.wrapper}>
                <div className={`${styles.dFlex} ${styles.mb25}`}>
                    <Search
                        value={searchFilter}
                        onChange={(value: string) => setSearchFilter(value)}
                    />
                </div>
                <div className={styles.mb3}>
                    {tableData && (
                        <Table columns={columns} data={tableData} globalFilter={searchFilter} />
                    )}
                </div>
                <div className={styles.flexEnd}>
                    <Button
                        type={'button'}
                        action={'create'}
                        onClickHandler={() => setOpenModal(true)}
                    >
                        New
                    </Button>
                </div>
            </div>

            <Modal header={'Dictionary country'} isOpen={openModal} closeModal={closeModal}>
                {activeId ? (
                    <DictionaryCountryDetail id={activeId} closeModal={closeModal} />
                ) : (
                    <DictionaryCountryCreateForm closeModal={closeModal} />
                )}
            </Modal>
        </Page>
    )
}
