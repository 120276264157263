import { useEffect, useMemo, useRef, useState } from 'react'
import x_spreadsheet, { Options } from 'x-data-spreadsheet'
import 'x-data-spreadsheet/dist/xspreadsheet.css'
import styles from './index.module.scss'
import { FixType } from '../../types/data'
import { Button } from '../button'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'

interface TypeProps {
    data: FixType[]
    options: Options
    height?: string
    width?: string
    exportData: {
        isLoading: boolean
        cb: (data: FixType) => void
    }
}

export const Spreadsheet = (props: TypeProps) => {
    const divRef = useRef<FixType>()
    const [sheet, setSheet] = useState<x_spreadsheet>()
    const [data, setData] = useState<FixType[]>(props.data || {})
    const [isChanged, setIsChanged] = useState(false)
    const fsHandle = useFullScreenHandle()

    const options = useMemo(() => {
        return {
            view: {
                height: () =>
                    (divRef.current && divRef.current.clientHeight) ||
                    document.documentElement.clientHeight,
                width: () =>
                    (divRef.current && divRef.current.clientWidth) ||
                    document.documentElement.clientWidth,
            },
            ...props.options,
        }
    }, [divRef, props.options])

    useEffect(() => {
        if (divRef.current && !sheet) {
            const sheet = new x_spreadsheet(divRef.current, options)
            sheet.loadData(data).change((data: FixType) => {
                setIsChanged(true)
                setData(data)
            })
            setSheet(sheet)
        }

        return () => {
            if (divRef.current) divRef.current.innerHTML = ''
        }
    }, [divRef, options])

    return (
        <>
            <FullScreen className={styles.spreadsheetContainer} handle={fsHandle}>
                <div className={styles.spreadsheetActions}>
                    <Button
                        disabled={!isChanged || props.exportData.isLoading}
                        type={'submit'}
                        action={'create'}
                        onClickHandler={() => props.exportData.cb(sheet?.getData())}
                    >
                        Save new revision
                    </Button>
                    <Button
                        type={'button'}
                        className={styles.spreadsheetFullScreen}
                        onClickHandler={fsHandle[!fsHandle.active ? 'enter' : 'exit']}
                    ></Button>
                </div>

                <div
                    style={{ height: props.height || '100%', width: props.width || '100%' }}
                    ref={divRef}
                ></div>
            </FullScreen>
        </>
    )
}
