import styles from './index.module.scss'

type TypeProps = {
    value: string
    disabled?: boolean
    onChange(value: string): void
}

export const Search = ({ value, disabled, onChange }: TypeProps) => {
    return (
        <input
            className={styles.inputSearch}
            type={'search'}
            value={value}
            onChange={(evt) => onChange(evt.target.value)}
            placeholder={'Search'}
            disabled={disabled}
        />
    )
}
